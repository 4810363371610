/* Overall container */
.notificationsContainer {
  display: flex;
  min-height: 100vh;
  color: #000;
  margin-top: 60px;
  background-color: #f7f7f7;
}

/* Main content styling */
.mainContent {
  flex: 1;
  padding: 0px 20px;
  margin-left: 250px; /* Sidebar width when expanded */
  transition: margin-left 0.3s ease;
}

.contentCollapsed {
  margin-left: 70px; /* Sidebar width when collapsed */
}

/* Stat box at the top */
.statsBar {
  display: flex;
  margin: 20px 20px;
}

.statBox {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.statLabel {
  font-size: 0.9rem;
  color: #555;
}

/* Notification List */
.notificationList {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Each notification as a card */
.notificationItem {
  background-color: #fff;
  margin: 10px 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.notificationItem:hover {
  background-color: #f5f5f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.unread {
  background-color: #e6f7ff;
}

.read {
  background-color: #fff;
}

/* Notification content layout */
.notificationContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Main info section on the left */
.mainInfo {
  display: flex;
  align-items: center;
  flex: 1;
}

/* Type icon */
.typeIconWrapper {
  margin-right: 10px;
}

.typeIcon {
  font-size: 20px;
  color: #79a6b7;
}

/* Profile picture */
.profile_picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

/* Notification text and time */
.notificationText {
  flex: 1;
}

.notificationContent {
  margin: 0;
  font-size: 14px;
}

.senderLink {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.senderLink:hover {
  text-decoration: underline;
}

.notificationTime {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

/* Actions on the right */
.actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Action button styling */
.actionButton {
  background: none;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
}

.actionButton:hover {
  color: #000;
}

/* No notifications text */
.noNotifications {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

/* --- Skeleton Styles --- */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeletonShimmer {
  position: relative;
  overflow: hidden;
  background-color: #eee;
}

.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

.skeletonContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px;
}

.skeletonNotification {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.skeletonAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}

.skeletonAvatar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

.skeletonTextWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.skeletonLine {
  height: 12px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* Skeleton actions (optional) */
.skeletonActions {
  width: 50px;
  height: 20px;
  background-color: #ddd;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.skeletonActions::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .mainContent {
    margin-left: 0 !important;
    width: 100%;
  }
  .contentCollapsed {
    margin-left: 0 !important;
  }
}

@media (max-width: 600px) {

  .mainContent {
    padding-top: 0;
  }
 
  .actions {
    align-self: flex-end;
    margin-top: 5px;
  }
  .notificationText {
    margin-left: 0;
  }
  .notificationTime {
    margin-top: 5px;
  }

  .statsBar {
    margin: 0 20px;
  }
}

@media (max-width: 400px) {
  .notificationItem {
    padding: 10px;
    margin: 5px;
  }
  .profile_picture {
    width: 30px;
    height: 30px;
  }
  .typeIcon {
    font-size: 16px;
  }
  .notificationContent {
    font-size: 12px;
  }
  .notificationTime {
    font-size: 10px;
  }
  .actionButton {
    font-size: 14px;
    padding: 3px;
  }
  .statsBar {
    margin: 0 10px 10px 10px;
  }
}
