.header {
    background-color: #79a6b7; /* Blue/Gray tone */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
  }
  
  .logoSection {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .brandTitle {
    color: #ffffff;
    font-size: 1.8rem;
    margin: 0;
  }
  
  .nav {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .navLink {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    transition: opacity 0.2s ease;
  }
  
  .navLink:hover {
    opacity: 0.8;
  }
  
  .loginButton {
    background-color: #d24b4c; /* Red/Orange */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 14px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .loginButton:hover {
    background-color: #a8463e;
  }

  @media (max-width: 1200px) {


.loginButton {
  padding: 6px 10px;
  font-size: 1.2rem;
}

.navLink {
    font-size: 1.2rem;
}

  }

  @media (max-width: 800px) {


  .header {
    padding: 10px;
  }

  .nav {
    gap: 20px;
  }


  }

  @media (max-width: 700px) {
    .brandTitle {
      display: none;
  }
}

  @media (max-width: 450px) {
    .brandTitle {
      display: none;
  }

  .logo {
      width: 35px;
      height: 35px;
  }

  .navLink {
      font-size: 0.8rem;
  }

  .loginButton {
    padding: 3px 10px;
    font-size: 0.8rem;
  }

  .nav {
    gap: 10px;
  }


}