/* src/components/YourMaps.module.css */

/* Container styles */
.myMapsContainer {
  display: flex;
  min-height: 100vh;
  color: black;
  margin-top: 60px;
  background-color: #f7f7f7;
}

/* Main content styling */
.myMapsContent {
  flex: 1;
  padding: 0px 20px;
  margin-left: 250px; /* Sidebar width when expanded */
}

.contentCollapsed {
  margin-left: 70px;
}

/* Stats Bar - now in a column layout */
.statsBar {
  display: flex;
  flex-direction: row; /* Stacks the stat boxes vertically */
  gap: 10px;
  margin: 20px 0;
  align-items: flex-start;
}

.statBox {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px 12px; /* Reduced padding */
  width: fit-content; /* Reduced width */
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statIcon {
  font-size: 1rem; /* Smaller icon size */
  margin-bottom: 3px;
  color: #333;
  margin: auto 3px;
}

.statValue {
  font-size: 1.2rem; /* Reduced font size */
  font-weight: bold;
  color: #333;
  margin: auto 3px;
}

.statLabel {
  font-size: 0.8rem; /* Reduced font size */
  color: #555;
  margin-top: 2px;
  margin-left: 3px;
}

/* Grid layout for cards */
.mapsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Map Card */
.mapCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%; /* Fixed height for smaller cards */
  width: 100%;
}

.mapCard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Thumbnail Area */
.thumbnail {
  width: 100%;
  height: 100%; /* Reduced thumbnail height */
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Card Body */
.cardBody {
  padding: 8px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Map Title */
.mapTitle {
  font-size: 1rem; /* Smaller font size */
  margin: 0 0 3px 0; /* Reduced margin */
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Details Row */
.detailsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem; /* Smaller font size */
  color: #666;
  margin-bottom: 4px; /* Reduced margin */
}

.modified {
  font-style: italic;
}

.visibility {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  color: #333;
}

.visibilityIcon {
  font-size: 1rem; /* Slightly smaller icon */
}

/* Stats Row (Stars) */
.statsRow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.8rem; /* Adjusted font size */
}

.starCount {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333;
}

.starIcon {
  color: #000;
  margin-right: 3px; /* Reduced margin */
}

/* Actions Row */
.actionsRow {
  display: flex;
  justify-content: space-between;
  gap: 5px; /* Reduced gap */
  margin-top: auto;
}

.actionsRow button {
  flex: 1;
  padding: 5px; /* Reduced padding */
  border: none;
  border-radius: 4px;
  font-size: 0.75rem; /* Smaller font size */
  cursor: pointer;
}

/* View Button: Blue/Gray */
.viewButton {
  background-color: #79a6b7;
  color: #fff;
}

.viewButton:hover {
  background-color: #6a8a9f;
}

/* Edit Button: Green */
.editButton {
  background-color: #55957b;
  color: #fff;
}

.editButton:hover {
  background-color: #3f7b65;
}

/* Delete Button: Red/Orange */
.deleteButton {
  background-color: #d24b4c;
  color: #fff;
}

.deleteButton:hover {
  background-color: #a03a39;
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  padding: 30px;
  width: 450px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.modalButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.deleteButtonModal {
  background-color: #d24b4c;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButtonModal:hover {
  background-color: #a03a39;
}

.cancelButton {
  background-color: #6c757d;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelButton:hover {
  background-color: #5a6268;
}

/* --- Skeleton Animation --- */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeletonShimmer {
  position: relative;
  overflow: hidden;
  background-color: #eee;
}
.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* --- Skeleton Stats Bar --- */
.skeletonStatsBar {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}
.skeletonStatBox {
  width: 100px;
  height: 50px;
  border-radius: 8px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonStatBox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* --- Skeleton Maps Grid --- */
.skeletonMapsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.skeletonMapCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
}
.skeletonThumbnail {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.skeletonThumbnail::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}
.skeletonLine {
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
}
.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* Responsive adjustments for smaller screens */

/* Force content to margin-left: 0 when width < 1000px */
@media (max-width: 1000px) {
  .myMapsContent {
    margin-left: 0 !important;
    width: 100%;
  }
  .contentCollapsed {
    margin-left: 0 !important;
  }
}

/* Tablet/Small screens: stats 2x2 grid */
@media (max-width: 600px) {

  .myMapsContent {
    padding-top: 0;
  }
  .statsBar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 0;
  }
  .statBox {
    width: auto;
    padding: 10px;
  }
  .statIcon {
    font-size: 1.4rem;
  }
  .statValue {
    font-size: 1.2rem;
  }
  .statLabel {
    font-size: 0.8rem;
  }
}

/* Very small phones */
@media (max-width: 400px) {
  .mapsGrid {
    grid-template-columns: 1fr;
  }
  .thumbnail {
    height: 100%;
  }
  .mapTitle {
    font-size: 0.9rem;
  }
  .actionsRow button {
    font-size: 1rem;
    padding: 8px;
  }
}

/* ------------------------------------------------
   Match the Stats Bar skeleton to normal Stats Bar
   on smaller screens
---------------------------------------------------*/
@media (max-width: 600px) {
  /* The real statsBar becomes a 2x2 grid, so do the same skeletonStatsBar */
  .skeletonStatsBar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 0;
  }
  .skeletonStatBox {
    width: auto;  /* Don’t force a fixed width on small screens */
    margin: 0;
  }
}

/* ------------------------------------------------
   Match the skeletonMapsGrid to .mapsGrid 
   for narrower breakpoints
---------------------------------------------------*/
@media (max-width: 400px) {
  .skeletonMapsGrid {
    grid-template-columns: 1fr; /* Single column, same as .mapsGrid */
  }

  .skeletonThumbnail {
    height: 100%; /* Let it stretch if you want a taller placeholder */
  }
}
