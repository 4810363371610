#euMap {

    position: relative;

	

}



.title-map-eu {
	font-size: 13px;
	letter-spacing: 0.5px;
	font-weight: 500;
	font-weight: 600;
    overflow-wrap: break-word;
}

.group-title-map-eu {
    font-size: 11px;
	letter-spacing: 0.5px;
	font-weight: 500;
}

.svg-eu {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto; /* center horizontally */
	max-height: 750px;

  }
  .svg-eu--large {
	max-height: none;
  }

  #euMap {
	display: block;
	height: auto;
	margin: 0 auto; /* center horizontally */
  }
@media (max-width: 735px) {
	#euMap {
	   width: 100%;
	}
   }