.changePasswordContainer {
  display: flex;
  min-height: 100vh;
}

.changePasswordContent {
  flex: 1;
  padding: 20px;
  margin-left: 250px; /* or your sidebar width */
  transition: margin-left 0.3s ease;
  position: relative;
}

.contentCollapsed {
  margin-left: 70px; /* collapsed sidebar width */
}

/* New centering container for the form */
.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Adjust this value based on your Header's height */
}

/* ChangePassword.module.css */

.formContainer {
  width: 100%;
  max-width: 400px;  /* Increase max-width as needed */
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto;
}


/* Remaining styles */
.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.formGroup input {
  width: 100%;
  padding: 10px 0px 10px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}



.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.successMessage {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.errorText {
  color: #d24b4c;
  font-size: 0.9rem;
  margin-top: 5px;
}

.submitButton {
  background-color: #55957b;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.submitButton:hover {
  background-color: #49846d;
}


.passwordRequirementsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin: 15px 0;
}

.passwordRequirementItem {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #666;
}

.requirementIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: gray;
  margin-right: 5px;
}

.valid {
  background-color: #48b17d;
}

.invalid {
  background-color: gray;
}

.passwordMatch {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8rem;
  margin: 10px 0 15px;
  color: #666;
}

.successIcon {
  color: #48b17d;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .changePasswordContent {
    margin-left: 0;
  }


}