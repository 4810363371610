/*
 * Below are Cascading Style Sheet (CSS) definitions in use in this file,
 * which allow easily changing how countries are displayed.
 *
 * The styles are applied in the order in which they are defined (and re-defined) here in the preamble.
 */




/*
 * Circles around small countries and territories
 *
 * Change opacity to 1 to display all circles
 */
.circlexx
{
	opacity: 0;
	fill: #c0c0c0;
	stroke: #4b4b4b;
	stroke-width: 0.5;
}

/*
 * Smaller circles around subnational territories: Australian external territories, Chinese SARs, Dutch special municipalities, and French DOMs (overseas regions/departments) [but not French COMs (overseas collectivities)]
 *
 * Change opacity to 1 to display all circles
 */
.subxx
{
	opacity: 0;
	fill: #c0c0c0;
	stroke: #4b4b4b;
	stroke-width: 1;

}


/*
 * Land
 * (all land, as opposed to water, should belong to this class; in order to modify the coastline for land pieces with no borders on them a special class "coastxx" has been added below)
 */
.landxx
{
	stroke: #4b4b4b;
	stroke-width: 0.5;
	fill-rule: evenodd;
	fill: #c0c0c0;
	
	
}

/*
 * Styles for coastlines of islands and continents with no borders on them
 * (all of them should also belong to the class "landxx" - to allow for all land to be modified at once by refining "landxx" style's definition further down)
 */
.coastxx
{
	stroke-width: 0.3;
	stroke: #4b4b4b;
}


/*
 * Styles for territories without permanent population (the largest of which is Antarctica)
 *
 * Change opacity to 0 to hide all territories
 */
.antxx
{
	stroke: #4b4b4b;
	stroke-width: 0.5;
	opacity: 1;
	fill: #c0c0c0;
}

/*
 * Circles around small countries without permanent population
 *
 * Change opacity to 1 to display all circles
 */
.noxx
{
	opacity: 0;
	fill: #c0c0c0;
	stroke: #4b4b4b;
	stroke-width: 0.5;
}


/*
 * Styles for territories with limited or no recognition
 * (all of them - including Taiwan - are overlays (i.e. duplicate layers) over their "host" countries, and so not showing them doesn't leave any gaps on the map)
 *
 * Change opacity to 1 to display all territories
 */
.limitxx
{
	opacity: 0;
	fill: #c0c0c0;
	stroke: #ffffff;
	stroke-width: 0.2;
	fill-rule: evenodd;
}

/*
 * Smaller circles around small territories with limited or no recognition
 *
 * Change opacity to 1 to display all circles
 */
.unxx
{
	opacity: 0;
	fill: #c0c0c0;
	stroke: #000000;
	stroke-width: 0.5;
}


/*
 * Oceans, seas, and large lakes
 */
.oceanxx
{
	opacity: 1;
	fill: #ffffff;
	stroke: #000000;
	stroke-width: 0.5;
}

/*
 * Reserved class names:
 *
 * .eu - for members of European Union
 * .eaeu - for members of Eurasian Economic Union
 */


/*
 * Additional style rules
 *
 * The following are examples of colouring countries.
 * These can be substituted with custom styles to colour the countries on the map.
 *
 * Colour a few countries:
 *
 * .gb, .au, .nc
 * {
 * 	fill: #ff0000;
 * }
 *
 * Colour a few small-country circles (along with the countries):
 *
 * .ms, .ky
 * {
 * 	opacity: 1;
 * 	fill: #ff0000;
 * }
 *
 */

 .group-title-map{
	font-size: 26px;
	letter-spacing: 1.3px;
	font-weight: 500;
	
 }

 .title-map {
	font-size: 30px;
	letter-spacing: 1.3px;
	font-weight: 500;
	font-weight: 600;
}

#worldMap{
	background-color: rgba(255, 255, 255, 0);
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
	position: relative;
	width: 100%;
}

.groupsContainer {
	border: #4b4b4b 1px solid;
}


.svg-world {
	width: 100%;
    height: auto;
    max-height: 750px;
}

.svg-world--large {
  max-height: none;
}