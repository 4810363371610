.state-map {
    fill:#c0c0c0
}                        /* default color for all states not specified below */
.borders {
    stroke:#4b4b4b; stroke-width:1
}    /* color and width of borders between states */
.dccircle {
    display:yes
}                      /* set display to yes to show the circle for the District of Columbia, or none to hide it */

.separator1 {
    stroke:#4b4b4b; stroke-width:3

} 

.svg-us {
    width: 100%;
    height: auto;
    background-color: white;
    max-height: 750px;
}

.svg-us--large {
    max-height: none;
  }

.groups {
    fill:none;

}
/* color and width of lines around Alaska and Hawaii */

/* Individual states can be colored as follows:
.ks,.mt,.pa {fill:#0000FF}
.ca,.de {fill:#FF0000}
In this example, Kansas, Montana and Pennsylvania are colored blue, and California and Delaware are colored red.
Place this code in the empty space below. */

.title-map-us {
	font-size: 15px;
	letter-spacing: 0.5px;
	font-weight: 500;
	font-weight: 600;
    overflow-wrap: break-word;
}

.group-title-map-us{
	font-size: 12px;
	letter-spacing: 0.5px;
	font-weight: 500;
    
	
 }

#usMap{
    display: block;
    height: auto;
    margin: 0 auto; /* center horizontally */
    
    
}

 @media (max-width: 735px) {
    #usMap {
       width: 100%;
    }
   }

 
