/* src/components/Header.module.css */
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; 
 

  background: #fff;
  height: 60px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}


.headerLeft {
  display: flex;
  align-items: center;
  gap: 30px;
}

.headerTitle {
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px; /* Adjust size as needed */
  color: #333;
}


.hamburgerButton {
  display: flex;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 0px 0px 20px;
 
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  transition: opacity 0.2s ease;
}

.logoLink:active .logo {
  opacity: 0.6;
}


.headerRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.createMapButton {
  display: flex;
  align-items: center;
  background-color: none;
  color: black;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  transition: 0.3s ease;
}

.createMapButton .plusIcon {
  margin-right: 5px;
}

.createMapButton:hover {
  background-color: #bebebe;
}

.notificationWrapper {
  position: relative;
  margin-left: 15px;
}

.notificationBell {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  display: flex;
  color: #333;
  padding-right: 10px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Adjust as needed */
  height: 32px; /* Adjust as needed */
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

/* Apply hover on the icon container only */
.hamburgerButton:hover .iconWrapper,
.notificationBell:hover .iconWrapper {
  background-color: #d6d6d6;
}



.unreadBadge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #d24b4c;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-right: 8px;
  
}

.notificationPopout {
  position: absolute;
  right: 0;
  top: 35px;
  width: 350px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.notificationHeader h3 {
  margin: 0;
}

.markAllAsRead {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
}

.markAllAsRead:hover {
  text-decoration: underline;
}

.notificationList {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.notificationItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
}

.notificationItem:last-child {
  border-bottom: none;
}

.notificationItem.unread {
  background-color: #f9f9f9;
}

.notificationContentWrapper {
  display: flex;
}

.profile_picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.notificationText {
  flex: 1;
}

.notificationContent {
  margin: 0;
  font-size: 14px;
}

.notificationMeta {
  font-size: 12px;
  color: #888;
}

.senderName {
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.senderName:hover {
  text-decoration: underline;
}

.mapTitle {
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.mapTitle:hover {
  text-decoration: underline;
}

.viewAllLink {
  text-align: center;
  padding: 10px;
}

.viewAllLink a {
  color: #333;
  text-decoration: none;
}

.viewAllLink a:hover {
  text-decoration: underline;
}

.noNotifications {
  padding: 20px;
  text-align: center;
  color: #888;
}

/* Profile Picture and Dropdown Menu Styles */
.profileWrapper {
  position: relative;
  padding: 0px 30px 0px 10px;

}

.headerProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.headerProfilePicture:hover {
  opacity: 0.8;
  
}

.profileMenu {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;


}

.profileMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profileMenu li {
  padding: 10px;
  cursor: pointer;
}

.profileMenu li:hover {
  background-color: #f0f0f0;
}

.profileMenu a,
.logoutButton {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  padding: 0;
  font-size: 16px;
}

.logoutButton {
  cursor: pointer;
}

.logoutButton:hover {
  background-color: #f0f0f0;
}

/* New Profile Menu Styles */
.profileMenu {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px; /* Wider than before */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 10px 0;
}

.profileMenuHeader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.profileMenuAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.profileMenuInfo {
  display: flex;
  flex-direction: column;
}

.profileMenuName {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.profileMenuUsername {
  color: #888;
  font-size: 14px;
}

.profileMenuList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profileMenuItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.profileMenuItem:hover {
  background-color: #f0f0f0;
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust the width/height to match the text line-height if needed */
  width: 20px;
  margin-right: 10px;
}
/* Adjust the icon so it aligns perfectly with the text */
.profileMenuIcon {
  font-size: 16px;
  line-height: 1; /* ensures the icon isn’t offset */
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  margin-top: 2px; 
}

/* make media for phone 600px */
@media (max-width: 600px) {
  .header {
    height: 50px;
  }

  .logo {
    height: 30px;
    width: 30px;
  }

  .headerLeft {
    gap: 10px
  }

  .headerTitle {
    font-size: 16px;
  }

  .createMapButton {
    padding: 6px 10px;
    font-size: 10px;
  }

  .headerRight {
    gap: 5px;
  }
  
  .profileWrapper {
    padding-right: 15px;
  }

  .hamburgerButton {
    padding-left: 10px;
  }

  .profile_picture {
    width: 30px;
    height: 30px;
  }

  .notificationHeader {
    font-size: smaller;
  }

  .notificationContent {
    font-size: 12px;
  }

  .notificationMeta {
    font-size: 10px;
    margin: 0;
  }

  .markAllAsRead {
    font-size: 10px;
  }

  .viewAllLink {
    font-size: smaller;
  }

  .notificationPopout {
    width: 300px;
    top: 42px;
    right: -50px
  }
  .createMapButton {
    display: none;
  }
}

/* make media for phone 370px */
@media (max-width: 400px) {
  

  .headerRight {
    margin-right: 20px;
  }

  .notificationPopout {
    right: -50px;
    top: 42px;
    width: 250px;
  }
  }
