.stepsContainer {
    /* Full width & full height, distinct background */
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
  
    /* Center section heading and the steps */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    text-align: center; /* center text overall */
    padding: 20px 20px;    /* optional side padding */
    box-sizing: border-box;
  }
  
  /* Section heading */
  .sectionTitle {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Wrapper for the step "cards" */
  .stepsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    /* If the content becomes too wide, it can wrap on smaller screens */
    flex-wrap: wrap; 
  }
  
  /* Each individual step card */
  .stepCard {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    padding: 40px 20px;
    width: 280px; /* fixed width so they're uniform */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* The icon at the top of each step */
  .stepIcon {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  /* Title for each step */
  .stepTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Subtext for each step */
  .stepText {
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {

    .stepsContainer {
      height: auto;
    }
    .sectionTitle {
      margin-bottom: 20px;
    }
  
    .stepsWrapper {
      flex-direction: column; /* stack the cards vertically */
      gap: 20px;
    }
  }

  @media (max-width: 450px) {
    .stepsContainer {
      height: auto; /* remove fixed height */
    }
  }

  