/* Container */
.profilePageContainer {
  display: flex;
  margin-top: 60px;
  background-color: #f7f7f7;
  min-height: auto; /* or comment out if you need 1000px */
  overflow-x: hidden; 
}

/* Main content (beside sidebar) */
.profileContent {
  flex: 1;
  padding: 20px 40px;
  margin-left: 250px; /* Default expanded sidebar width */
  min-height: 100rem;
}

.contentCollapsed {
  margin-left: 80px; /* Collapsed sidebar width */
}

/* Main Container: holds leftColumn + rightColumn */
.mainContainer {
  display: flex;
  gap: 20px;
  margin: 20px auto;
  width: 90%;
}

/* Left Column - Profile Info */
.leftColumn {
  width: 380px;
}

/* Profile Info Box */
.profileInfoBox {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  text-align: center;
}

.profilePictureWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.profilePicture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  font-size: 1.6rem;
  color: gray;
  margin: 0;
}

.fullName {
  font-size: 2.2rem;
  margin: 5px 0 10px;
}

.infoRow {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.infoItem {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #666;
}

.icon {
  margin-right: 5px;
  color: #888;
}

.statsRowInfo {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}

.statsItem {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1rem;
  color: #333;
}

.bio {
  margin-top: 10px;
  font-size: 1.05rem;
  color: #444;
  width: fit-content;
  margin: 10px auto;
  text-align: left;


}

.editProfileButton {
  margin-top: 20px;
  padding: 10px 16px;
  background-color: #55957b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.editProfileButton:hover {
  background-color: #4a846d;
}

.privateLabel {
  display: flex;
  flex-direction: column;
 
}

.privateLabel {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  color: #333;
  align-items: center;
}

.lockIconSmall {
  margin: 0 3px;
}
/* Right Column - Tabs */
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* This row for the tab's top bar */
.topBarRow {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 10px; /* optional spacing */
}

/* Left side: "sortRowLeft" */
.sortRowLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Right side: "pageCountRight" */
.pageCountRight {
  font-weight: 500; 
  /* or whatever style you want */
}

.tabs {
  display: flex;
  gap: 10px;
}

.tabButton {
  background: none;
  border: 2px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  color: #444;
  transition: 0.2s, border-color 0.2s;
  display: flex;
  align-items: center;

}
.tabButton:hover {
  background: #f0f0f0;
}
.activeTab {
  background: #eaeaea;
  border-color: #ccc;
  font-weight: bold;
  color: #000;

}

.tabIcon {
  font-size: 1.2rem;
  margin-right: 6px;
}

.tabContent {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  flex: 1;
}

/* Sort row above the grid */
.sortRow {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.95rem;
  margin-bottom: 20px;
}
.sortSelect {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* Cards Grid */
.cardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Map Card */
.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.cardThumbnail {
  width: 100%;
  height: 180px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.cardBody {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardTitle {
  font-size: 1.2rem;
  margin: 0 0 5px;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}

.modified {
  font-style: italic;
}

.starDisplay {
  display: flex;
  font-weight: bold;
  color: #333;
}

.starIcon {
  margin-right: 4px;
  color: #333;
}

/* Activity Tab */
.activityTabContent {
  padding: 20px;
}

/* Loading Container */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Error message (optional) */
.error {
  color: #ff3333;
  font-weight: bold;
}

.reportProfileButton {
  display: inline-flex;
  align-items: center;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  color: #555;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  padding: 4px 8px;
  transition: background-color 0.2s, color 0.2s;

}
.reportProfileButton:hover {
  background-color: #e0e0e0;
}
.reportProfileButton.active {
  background-color: #79a6b7;
  color: #fff;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: currentColor;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.reportOptions {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.reportOption {
  margin-bottom: 0.5rem;
}

.reportDetails textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.modalActions button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #ccc;
}

.modalActions button:first-child {
  background-color: #79a6b7;
  color: #fff;
}

.disabledSection {
  display: flex;
  align-items: center;
}

.lockIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  fill: currentColor;
}

/* Loading states, success container, etc. */
.loadingContainer,
.successContainer {
  text-align: center;
  padding: 2rem;
}

.pagination {
  display: flex;
  gap: 5px;
  margin-top: 20px;
  justify-content: center;
}

.pagination button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover:not(:disabled) {
  background-color: #ccc;
}

.pagination button:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.activePage {
  background-color: #999; /* or any highlight color you want */
  color: #fff;
}



/* RESPONSIVE RULES */

/* 1) Remove left margin below 1000px */
@media (max-width: 1000px) {
  .profileContent {
    margin-left: 0 !important;
    width: 100%;
  }
  .contentCollapsed {
    margin-left: 0 !important;
  }
}

/* 2) Stack columns below 768px */
@media (max-width: 768px) {
  .mainContainer {
    flex-direction: column;
  }
  .leftColumn {
    width: 100%;
  }
  .rightColumn {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .profileContent {
    padding: 0px;
  }

  .activityTabContent {
    padding: 0px;
  }

  .tabContent {
    padding: 10px;
  }

  .cardThumbnail {
    height: 100%; /* smaller */
  }
}

/* 3) Further shrink for very small ~400px screens */
@media (max-width: 400px) {
  .fullName {
    font-size: 1.6rem;
  }
  .bio {
    font-size: 0.95rem;
  }
  .cardsGrid {
    grid-template-columns: 1fr; /* single column */
  }
  
  .tabButton {
    font-size: 0.9rem;
    padding: 6px 8px;
  }
  .cardTitle {
    font-size: 1rem;
  }
  .infoItem {
    font-size: 0.9rem;
  }
  .statsItem {
    font-size: 0.9rem;
  }
}


/* --------------------------------------------------------------------- */
/* -- SKELETON SECTION (new) ------------------------------------------- */
/* --------------------------------------------------------------------- */

/* Basic shimmer keyframe used for all skeleton placeholders */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeletonShimmer {
  position: relative;
  overflow: hidden;
  background-color: #eee; /* base color of skeleton */
}
.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* The container that mimics .mainContainer layout during skeleton */
.skeletonProfileContainer {
  display: flex;
  gap: 20px;
  margin: 20px auto;
  width: 90%;
}

/* Left column skeleton: matches the size of .leftColumn & .profileInfoBox */
.skeletonProfileLeftColumn {
  width: 320px;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* 2) Apply box-sizing to skeleton columns so padding doesn't make them exceed 100% */
.skeletonProfileLeftColumn,
.skeletonProfileRightColumn {
  box-sizing: border-box;
}


.skeletonProfilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ddd;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

/* Generic skeleton line (like a text line). Adjust width/height inline. */
.skeletonLine {
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background-color: #eee;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
}

/* Right column skeleton: matches the size of .rightColumn & .tabContent */
.skeletonProfileRightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Skeleton placeholders for the tabs row */
.skeletonTabs {
  display: flex;
  gap: 10px;
}

.skeletonTab {
  border-radius: 4px;
  background-color: #ddd;
}

/* The skeleton grid for map cards or starred cards */
.skeletonCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 10px;
}

/* Skeleton card that mimics .card structure */
.skeletonCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;
}

/* The "thumbnail" area on the card */
.skeletonCardThumbnail {
  width: 100%;
  height: 180px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

/* Skeleton lines within the card body */
.skeletonCardText {
  width: 80%;
  height: 14px;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

.skeletonCardTextShort {
  width: 40%;
  height: 14px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

/* For the private profile box in skeleton if needed (not used here) */
.privateProfileBox {
  text-align: center;
}

/* ------------------------------------------
   SKELETON RESPONSIVE STYLES
   Matches .mainContainer breakpoints
------------------------------------------- */
/* 3) On smaller screens, reduce default widths/padding for skeleton columns */
@media (max-width: 768px) {
  .skeletonProfileContainer {
    flex-direction: column;       /* already present, ensure it's applied */
    width: 95%;                   /* narrower container so it fits more snugly */
    margin: 10px auto;           /* reduce margin */
  }
  
  .skeletonProfileLeftColumn {
    width: 100% !important;      /* override the 320px for mobile */
    margin-bottom: 20px;
    padding: 20px;               /* reduce from 30px if needed */
  }
  
  .skeletonProfilePic {
    width: 120px;                /* scale down the skeleton pic */
    height: 120px;
  }
  
  .skeletonProfileRightColumn {
    width: 100% !important;
    padding: 0 10px;             /* optional padding if you want some space */
  }
}

/* 4) On very small screens (under 400px), shrink things further */
@media (max-width: 400px) {
  /* Force the skeleton container narrower if needed */
  .skeletonProfileContainer {
    width: 90%;
    margin: 10px auto;
  }

  .skeletonProfileLeftColumn {
    padding: 15px;       /* even smaller padding if needed */
  }
  
  .skeletonProfilePic {
    width: 90px;         /* scale the circle down more */
    height: 90px;
  }

  /* Also ensure the skeleton grid on the right side remains single column */
  .skeletonCardsGrid {
    grid-template-columns: 1fr;
  }
  
  /* If your "thumbnail" is too tall, reduce again */
  .skeletonCardThumbnail {
    height: 140px;
  }
}