/* Explore.module.css */

/* ----------------------------
   LAYOUT WRAPPER
---------------------------- */
.explorePageContainer {
  display: flex;
  min-height: 100vh;
  color: black;
  margin-top: 60px; /* If your header is fixed at 60px */
  background-color: #f7f7f7;
}

/* Main wrapper for the content area (beside the sidebar) */
.mainContentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px; /* width when sidebar is expanded */
  padding: 15px;      /* smaller padding */
}

.mainContentWrapper.collapsed {
  margin-left: 70px; /* collapsed sidebar width */
}

.exploreContent {
  padding: 15px; /* smaller padding */
  box-sizing: border-box;
  width: 100%;
}

/* Overlay for small screens if sidebar is open */
.sidebarOverlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background: rgba(0, 0, 0, 0.4);
  z-index: 998;
}

/* ----------------------------
   TOP BAR (SORT + SEARCH)
---------------------------- */
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; 
}

/* Sort Tabs */
.sortTabs {
  display: flex;
  align-items: center;
}

.sortByLabel {
  font-weight: 500;
  margin-right: 8px;
  font-size: 0.9rem;
  color: #555;
}

.tabButton {
  background-color: transparent;
  border: none;
  padding: 6px 10px; 
  cursor: pointer;
  font-size: 0.9rem;
  margin-right: 6px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease;
}

.tabButton:hover {
  border-bottom: 2px solid #aaa;
}

.activeTab {
  border-bottom-color: #333;
  font-weight: 600;
}

/* Search Form */
.searchForm {
  display: flex;
  align-items: center;
}

.searchInputContainer {
  display: flex;
  align-items: center;
}

.searchInputContainer input {
  padding: 6px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  width: 220px;
  transition: border-color 0.3s ease;
}

.searchInputContainer input:focus {
  border-color: #79a6b7;
  outline: none;
}

.searchButton {
  background-color: #ccc;
  border: 1px solid #ccc;
  border-left: none;
  padding: 6px 10px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 0.9rem;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchButton:hover {
  background-color: #bbb;
}

/* "Clear Search" - smaller label & padding */
.clearSearchButton {
  background-color: #ccc;
  border: 1px solid #ccc;
  padding: 4px 6px;
  border-radius: 4px 0 0 4px; 
  margin-right: 8px;
  cursor: pointer;
  font-size: 0.75rem;
  color: #555;
  transition: background-color 0.3s ease;
}

.clearSearchButton:hover {
  background-color: #bbb;
}

/* ----------------------------
   RESULTS ROW
---------------------------- */
.resultsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.totalResults {
  font-weight: 500;
  font-size: 0.9rem;
  color: #444;
}

.selectedTagsRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selectedTagsList {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.selectedTagBox {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 0.8rem;
}

.selectedTagText {
  margin-right: 6px;
}

.removeTagButton {
  background: none;
  border: none;
  font-size: 0.8rem;
  color: #555;
  cursor: pointer;
  padding: 0;
}

.removeTagButton:hover {
  color: #333;
  font-weight: bold;
}

/* ----------------------------
   MAIN CONTENT (MAPS + SIDEBAR)
---------------------------- */
.mainContent {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.mapsSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Grid of map cards => min 220px to match skeleton */
.mapsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 15px;
}

/* ----------------------------
   MAP CARD
---------------------------- */
.mapCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.mapCard:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Match skeleton's 120px height */
.thumbnail {
  width: 100%;
  height: 140px; /* was 100% or 200px, now fixed to 120px */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}

.thumbnail svg {
  width: 100%;
  height: 100%;
}

/* Title + map info => smaller font, margins */
.mapTitle {
  font-size: 1rem;
  margin: 8px;
  margin-bottom: 4px;
  color: #333;
}

.mapInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 8px 8px;
  font-size: 0.8rem;
  color: #555;
}

.starCountContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

/* Tag chips in the card => smaller */
.tags {
  margin: 0 8px 8px 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tag {
  display: inline-block;
  background-color: #e0e0e0;
  color: #333;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 0.75rem;
}

/* ----------------------------
   PAGINATION
---------------------------- */
.pagination {
  display: flex;
  gap: 5px;
  margin-top: 15px;
  justify-content: center;
}

.pagination button {
  padding: 5px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.pagination button:hover:not(:disabled) {
  background-color: #ccc;
}

.pagination button:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.activePage {
  background: #333;
  color: #fff;
}

/* ----------------------------
   TAG SIDEBAR
---------------------------- */
.tagsSidebar {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px; 
  flex-shrink: 0;
  width: 200px;
  min-width: 200px;
  height: auto;
  align-self: flex-start;
}

.tagsSidebar h2 {
  margin: 0 0 8px 0;
  font-size: 1rem;
}

.tagsList {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tagCheckbox {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
}

.tagCheckbox input {
  margin-right: 6px;
}

.checkboxTag {
  display: inline-flex;
  align-items: center;
}

/* ----------------------------
   SHIMMER ANIMATION (SKELETON)
---------------------------- */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeletonShimmer {
  position: relative;
  overflow: hidden;
  background-color: #eee;
}
.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* ----------------------------
   SKELETON COMPONENTS
---------------------------- */
.skeletonTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.skeletonSortTabs {
  display: flex;
  gap: 8px;
}
.skeletonSortTab {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonSortTab::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* Skeleton search form */
.skeletonSearchForm {
  display: flex;
  gap: 8px;
  align-items: center;
}
.skeletonSearchInput {
  width: 150px;
  height: 20px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonSearchInput::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}
.skeletonSearchButton {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonSearchButton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* skeleton results row */
.skeletonResultsRow {
  margin-bottom: 10px;
}
.skeletonResultsText {
  width: 120px;
  height: 14px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonResultsText::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* skeleton main content area */
.skeletonMainContent {
  display: flex;
  gap: 15px;
}

/* skeleton map cards area => 220px min, same as real */
.skeletonMapsGrid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 15px;
}
.skeletonMapCard {
  background-color: #eee;
  position: relative;
  border-radius: 6px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.skeletonMapCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* skeleton card thumbnail => 120px tall */
.skeletonThumb {
  width: 100%;
  height: 140px;
  border-radius: 4px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}
.skeletonThumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* skeleton lines for text in card => 12px */
.skeletonLine {
  width: 100%;
  height: 12px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* skeleton right sidebar => 200px wide */
.skeletonTagsSidebar {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.skeletonTagTitle {
  width: 100px;
  height: 14px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.skeletonTagTitle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}
.skeletonTagLine {
  width: 100%;
  height: 12px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.skeletonTagLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* skeleton pagination */
.skeletonPagination {
  margin-top: 15px;
  display: flex;
  gap: 6px;
  justify-content: center;
}
.skeletonPageBtn {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeletonPageBtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  animation: shimmer 1.2s infinite;
}

/* ----------------------------
   RESPONSIVE BREAKPOINTS
---------------------------- */
@media (max-width: 1000px) {
  .mainContentWrapper {
    margin-left: 0 !important;
    padding: 10px;
    width: 100%;
  }
  .mainContentWrapper.collapsed {
    margin-left: 0 !important;
  }

  .mainContent {
    flex-direction: column;
  }

  .mapsSection {
    width: 100%;
    margin-bottom: 10px;
  }

  .tagsSidebar {
    width: 100%;
    min-width: auto;
  }

  .sortByLabel {
    font-size: 0.8rem;
  }

  .tabButton {
    padding: 6px;
    font-size: 0.8rem;
  }

  .sortTabs {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .mainContentWrapper.collapsed {
    margin-left: 60px;
  }
  .searchInputContainer input {
    width: 150px;
  }

  .topBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchForm {
    width: 100%;
  }

  .searchInputContainer {
    width: 100%;
    display: flex;
    flex-wrap: nowrap; 
  }

  .searchInputContainer input {
    flex: 1;
    width: auto;
  }

  .searchButton {
    flex-shrink: 0;
  }
  .thumbnail {
    height: 100%;
  }
}

@media (max-width: 400px) {
  .searchInputContainer input {
    width: 120px;
    font-size: 0.8rem;
    height: 20px;
  }

  .searchButton {
    height: 34px;
  }

  /* Make map cards a single column if you want */
  .mapsGrid {
    grid-template-columns: 1fr;
  }

  /* Even smaller card fonts */
  .mapCard {
    font-size: 0.8rem;
  }
  
  .mapTitle {
    font-size: 0.9rem;
    margin: 6px;
  }
  .mapInfoRow {
    font-size: 0.75rem;
    margin: 0 6px 6px 6px;
  }

  .tagsList {
    gap: 0px;
  }
}
