.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.header-git {
  position: absolute;
  top: 36px;
  right: 5%;
 
}

.nav-items {
  display: flex;
  flex-direction: row;

}

.home-Demo {
  width: 70%;
  display: flex;
  margin: 0 auto;

}

.home-sec{
    display: flex;
    flex-direction: column;
    color: white;
    
}


.home-slogan {
  font-size: 55px;
  margin-bottom: 0px;
  margin-top: 30px;
}

.home-subslogan {
  font-size: 25px;
  margin-top: 0px;
}

.home-description {
  font-size: 22px;
  width: 90%;
  margin: 30px auto;
  text-align: start;
  letter-spacing: 0.8px;
  line-height: 40px;
  max-width: 1000px;
  
  

}


.map-types p {
  max-width: 500px;
  font-size: 18px;
  margin-left: 30px;
  text-align: start;
}

.map-types h3 {
  margin: 0px 10px 10px 0px;
}

.map-types img {
  max-width: 500px;
  
  

}

.map-types a {
  all: unset;
  background-color: #495057;
  color: white;
  border-radius: 10px;
  transition: 0.3s;
  padding: 5px;
  
  

}

.map-types a:hover {
    background-color: #e9ecef;
    color: black;
}

.map-types {
  background-color: #212529;
}



.map-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: #212529 10px solid;
  width: fit-content;
  margin: 30px auto
  
}
.group {
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: fit-content;
  align-items: center;
  background-color: #e9ecef;

  
  
  
}

.group-eu {
  flex-direction: column;
  margin: 0px;
  height: fit-content;
  background-color: #f8f9fa;

  
  
  
}

.header {
  height: 100px;
  width: 100%;
  background-color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.header-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-nav li {
  font-size: 28px;
  
  
}

.header-dropdown {
  color: white;
  background-color: #747f8aea;
  font-size: 12px;
  padding:3px;
  position: absolute;
  display: none;
  overflow: hidden;
  max-height: 0;
  border-radius: 0px 0px 15px 15px;
  padding-bottom: 10px;
  
}

.header-nav a {
  font-size: 20px;
  color: white;
  text-decoration: none;
  transition: 0.3s;
  padding: 7px;
  border-radius: 4px;

}

.header-nav a:hover {
  background-color: white;
  color: #000;
  
  
}

.dropdown-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  
}

.footer {
  background-color: #212529;
  color: #fff;
  padding: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.footer p {
  margin: 0;
  font-size: 14px;
}

.logo {
  height: 75px;
  position: relative;
  float: left;
  
}

.logo-title {
  font-size: 19px;
  color: white;
}

.header {
  color: white;
}





li:hover > .header-dropdown {
  display: block;
  max-height: 1000px;
  
}



.nav-button {
  background-color: #495057;
  border: none;
  border-radius: 15px 15px 0px 0px;
  height: 40px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  flex: 1;
  transition: all 0.3s;
  width: 100%;
  max-width: 100%;
  
  
  
}

.nav-button h4 {
  margin:0px;
  padding-top: 7px;
}



.nav-button:hover {
  background-color: #f8f9fa6c;
  color: black;
}

.nav-button.active {
  background-color: #e9ecef;
  color:black
}

.nav-button.active:hover {
  background-color: #f8f9fa



}


.color-picker {
  background-color: #e9ecef;
  color: white;
  border: #e9ecef solid 5px;
  scale: 2;
  width:600px !important;
  margin-bottom: 25px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  
}






.clicked {
  background-color: white;
}

.class-items {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  padding-left: 0px;
  
  
}

.map-title {
  margin: 20px;
  font-size: 28px;
  border: none;
  font-weight: bold;
  flex-direction: column;
  color: black;
  background-color: #e9ecef;
  border-radius: 10px;
  padding-left: 4px;
  text-align: center;  
  
}

.download-box {
  background-color: #e9ecef;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius:0px 0px 15px 15px;
}


.group-title {
  margin: 10px;
  font-size: 30px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: black;
  text-align: center;
  
}

input.group-title {
  background-color: #e9ecef;
  border-radius: 5px;
  padding-left: 7px;
  height: 40px ;
  border: 3px solid #e9ecef;
}

.group-show {
  font-size: 12px;
  text-align: left;
  margin-left: 12px;
  font-weight: 400;
}


.group-set {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: #e9ecef;
  
}
.countries {
  background-color: #e9ecef;
  display: flex;
  flex-direction: column;
  text-align: left;
  color:black;
  
  
}

.countries ul {
  padding-left: 20px;
}
.letter {
  margin-top: 10px;
  margin-bottom: 10px;
}


.state-selector {
  display: flex;
  flex-direction: row;
  background-color: #e9ecef;
  text-align: left;
  justify-content: center;
  padding-bottom: 30px;
  
}

.state-selector-us{
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
  text-align: left;
  justify-content: center;
  padding-bottom: 30px;

}

.state-selector-eu{
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
  text-align: left;
  justify-content: center;
  padding-bottom: 30px;

}





.all-states-us ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  flex-direction: column;
  height: 415px;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 15px;

 
 
  

}

.all-states-us li {
  flex: 1 0 auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-width: 240px;
  max-width: 140px;
}





.all-states-eu ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  flex-direction: column;
  height: 416px;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 15px;

 
}


.all-states-eu li {
  display: flex;
  margin-top: 5px;
  font-size: 20px;
  min-width: 240px;
  max-width: 140px;
}




.country-label {
  font-size: 20px;
  margin: 4px;
  max-width: 240px;
}

.state-label {
  font-size: 20px;
  margin: 4px;

}

.country-label-eu {
  font-size: 20px;
  margin: 4px;
}




.country  {
  height: 14px;
  margin: 4px;
  margin-top: 6px
 
}

.states{
  height: 14px;
}

.oceana {
  background-color: #dee2e6;
  padding-left: 10px;
}


li {
  list-style: none;
}

.countries li {
  display: flex;
  align-items: center;
}

.states li {
  display: flex;
  align-items: center;
}

.country-eu li {
  display: flex;
  align-items: center;
}

.country-eu {
  margin: 4px;
}






.continents {
  text-align: center;
  display: flex;
  align-items: center;  
  margin-top: 0px;
  margin-bottom: 10px;
  
  
}

.con-icon {
  height: 19px;
  width: auto;
 

}

.con-label {
  font-weight: bold;
  margin-left: 4px;
  font-size: 16px;
  color: #808080;
 
}


.settings {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;


  
}

.map-settings {
  flex-direction: column;
  display: flex;
  text-align: left;
  padding: 20px;
  

  
}

.map-download {
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
  color: black;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  
  
  
}

.settings-title {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 0px;
  align-items: center;
  display: flex;
}

.settings-checkbox {
  height: 10px;
  margin: 2px;
}

.settings-label{
  color: white;
  font-size: 18px;
}

.settings-button {
  background-color: #e9ecef;
  border: 1px solid #495057;
  border-radius: 30px ;
  height: 30px;
  color:black;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
  margin-bottom: 0px;
  margin-left: 6px;
  
}

.settings-button:hover {
  background-color: #495057;
  color: white;
}

.settings-button.active {
  background-color: #495057;
  color:white
}

.settings-button.active:hover {
  background-color: #f8f9fa
}



.download-format {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-top: 2px;

}

.settings-label-r {
  color: white;
  font-size: 14px;
  
}

.settings-radio {
  height: 16px;
}



.loader {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.loader::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #000 transparent #000 transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loader-message {
  position: fixed;
  top: 39%;
  left: 50%;
  color: #000;
  transform: translate(-50%, -50%);

  
}
.remove-button {
  background-color: #747f8a;
  border-radius: 15px ;
  color:white;
  cursor: pointer;
  transition: all 0.3s;
  float: right;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

}

.remove-button:hover {
  background-color: #747f8a91;
}

.remove-button.active {
  background-color: #f8f9fa;
  color:black
}

.remove-button.active:hover {
  background-color: #f8f9fa
}

.remove-img {
  height: 15px;
  display: flex;
  align-items: center;
  
}

.add-button {
  background-color: #ffffff00;
  border: none;
  border-radius: 10px;
  color:white;
  cursor: pointer;
  transition: all 0.1s;
  display: flex;
  float: right;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  


}

.add-button:hover {
  background-color: #ffffff21;
}

.add-button:active{
  background-color: rgb(255, 255, 255);
  transition:  1s ease;
}





.add-img {
  height: 30px;
  display: flex;
  align-items: center;
  
}

@media (max-width: 1350px) {
  .color-picker {
    width: 300px !important;
  }
  .all-states-eu ul {
    height: 690px;
  }
  .all-states-us ul {
    height: 700px;
  }

  .country-label {
    font-size: 16px;
  }
  
 

}

@media (max-width: 735px) {

  .home-slogan {
    font-size: 25px;
  }

  .home-subslogan {
    font-size: 19px;
  }

  .home-Demo {
    width: 100%;
  }

  .home-description {
    font-size: 14px;
  }

  .map-row {
    flex-direction: column;
  }
  .map-types img {
    width: 100%;
  }

  .footer p {
    font-size: 10px;
  }


  .header {
    height: 60px;
  }
  .logo {
    height: 50px;
  }

  .header-nav li{
    font-size: 20px;
  }

  .header-git {
    display: none;
   
    
  }

  .map-title {
    margin: 10px;
    font-size: 20px;
    width: 180px;
    
  }

  .settings-title {
    font-size: 12px;
    
  }

  .settings-button {
    height: 25px;
    font-size: 12px;
   
  }

  .download-format {
    margin-top: 4.5px;
  }
  .nav-button {
    height: 40px;
  }
.nav-button h4 {
  font-size: 12px;
}

  .remove-img {
    height: 10px;
  }

  .remove-button {
    height: 20px;
    width: 20px;
  }
  .add-img {
    height: 20px;
    
  }

  .add-button {
    margin: 0px;
  }
  
  .group-title input{
    font-size: 30px;
    width: 260px;
    padding-left: 0px;
  }

  .color-picker {
    width: 170px !important;
    margin-top: 50px;
    font-size: 14px;
    
  }

  .group-set {
    width: 100%;
  }

  .countries {
    margin-top: 20px;
  }

  .country-label {
    font-size: 20px;
  }

  .country {
    height: 16px;
  }

  .oceana {
    background-color: #e9ecef;
  }

  .state-selector {
    flex-direction: column;
  }

  .all-states-us ul {
    flex-direction: row;
    margin-top: 40px;
    height: 100%;
  }

  .all-states-eu ul {
    flex-direction: row;
    margin-top: 40px;
    height: 100%;
  }
}

