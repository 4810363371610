/* Overall container */
.starredMapsContainer {
  display: flex;
  min-height: 100vh;
  color: black;
  margin-top: 60px;
  background-color: #f7f7f7;
}

/* Main content area */
.starredMapsContent {
  flex: 1;
  padding: 0px 20px;
  margin-left: 250px; /* Sidebar width when expanded */
  transition: margin-left 0.3s ease;
}

.contentCollapsed {
  margin-left: 70px; /* Adjust for collapsed sidebar */
}

/* Grid of map cards - matching YourMaps layout */
.mapsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Individual map card styling */
.mapCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.mapCard:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Thumbnail container */
.thumbnail {
  width: 100%;
  height: 150px; /* Adjusted to match YourMaps */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}

.thumbnail svg {
  width: 100%;
  height: 100%;
}

/* Map title */
.mapTitle {
  font-size: 1.2rem;
  margin: 10px;
  margin-bottom: 5px;
  color: #333;
}

/* Info row: creator and star count */
.mapInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 10px 10px;
  font-size: 0.9rem;
  color: #555;
}

/* Star icon styling */
.starIcon {
  color: black;
  margin-right: 5px;
}

/* Tags container */
.tags {
  margin: 0 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/* Individual tag */
.tag {
  display: inline-block;
  background-color: #e0e0e0;
  color: #333;
  padding: 2.5px 5px;
  border-radius: 4px;
  font-size: 12px;
}

/* --- Skeleton Animation --- */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeletonShimmer {
  position: relative;
  overflow: hidden;
  background-color: #eee;
}
.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* --- Skeleton Maps Grid --- */
.skeletonMapsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Skeleton map card */
.skeletonMapCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
}

/* Skeleton thumbnail */
.skeletonThumbnail {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.skeletonThumbnail::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* Skeleton line for text */
.skeletonLine {
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
}
.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* Responsive adjustments */

/* Under 1000px */
@media (max-width: 1000px) {
  .starredMapsContent {
    margin-left: 0 !important;
    padding: 10px !important;
    width: 100%;
  }
  .contentCollapsed {
    margin-left: 0 !important;
  }
}

/* Under 600px: two columns */
@media (max-width: 600px) {
  .starredMapsContent {
    padding-top: 0;
  }
  .mapsGrid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .mapTitle {
    font-size: 1rem;
  }
  .mapInfoRow {
    font-size: 0.8rem;
  }
}

/* Under 400px: single column */
@media (max-width: 400px) {
  .mapsGrid {
    grid-template-columns: 1fr;
  }
  .thumbnail {
    height: 100%;
  }
  .mapTitle {
    font-size: 0.9rem;
    margin: 8px;
    white-space: normal;
  }
  .mapInfoRow {
    font-size: 0.8rem;
    margin: 0 8px 8px 8px;
  }
}
