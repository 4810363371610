/* src/components/FullScreenLoader.module.css */
.loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6); /* semi-transparent dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* on top of everything */
  }
  
  .loaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .skeletonBox {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: #ccc;
    margin-bottom: 16px;
    animation: skeleton-shimmer 1.2s ease-in-out infinite;
  }
  
  .loaderText {
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
  }

  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #79a6b7; /* or any color you like */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-bottom: 16px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  
  /* "Shimmer" effect */
  @keyframes skeleton-shimmer {
    0% {
      background-color: #d4d4d4;
    }
    50% {
      background-color: #c0c0c0;
    }
    100% {
      background-color: #d4d4d4;
    }
  }
  