.splitContainer {
  display: flex;
  min-height: 100vh; /* Ensures it at least fills the screen vertically */
  font-family: Arial, sans-serif;
}


/* The go-back button in the top-left corner */
.goBackButton {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}



/* Left side: for the logo and branding */
.leftSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background: linear-gradient(to bottom right, #79c99e, #55957b);
}

.brandContainer {
  display: flex;
  flex-direction: row; 
  align-items: center;
  gap: 20px;
}

.logo {
  width: 130px;
  height: auto;
}

.brandText {
  color: #fff;
  font-size: 4rem; 
  font-weight: bold;
  text-align: center;
}

/* Right side: for the form */
.rightSide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

/* Container for the signup heading and form */
.signupBox {
  
  width: 90%;
  text-align: center;

}

.signupTitle {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

/* The signup form */
.signupForm {
  display: flex;
  flex-direction: column;
}

/* Row container for First/Last Name */
.formRow {
  display: flex;
  gap: 10px;


}



/* Individual form groups (label + input or select) */
.formGroup {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.formGroup input,
.formGroup select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5; /* Adjust line-height to prevent text overlap */

}

/* Container for the two columns in the form */
.formColumns {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

}

/* Left and right columns each take roughly half the width */
.leftColumn,
.rightColumn {
  flex: 1;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: flex-start;
  width: fit-content;
  margin: 0 auto;
}
/* The outer label with the checkbox + text container side by side */
.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  /* no gap here so the checkbox doesn't get huge spacing */
  color: #666;
}

/* Minimal right margin to separate checkbox from the text container */
.checkboxLabel input[type='checkbox'] {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 6px; /* Adjust to taste */
}

/* The inner container that holds the text, links, and final dot */
.termsText {
  /* Use inline-flex so items line up on a single row */
  display: inline-flex;
  align-items: center;
  gap: 5px; /* Gap between each child (span/button) except we'll handle the dot specially */
  font-size: 0.9rem;
}

/* Make the "Terms of Use" & "Privacy Policy" look link-like */
.privacyLink {
  background: none;
  border: none;
  color: #55957b;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.privacyLink:hover {
  text-decoration: none;
}

/* .dot can override margin if you want zero space before it.
   Right now, the container’s gap property will create a space 
   before the dot, so you can either leave the tiny gap or 
   pull it flush if you prefer: */
.dot {
  margin-left: -4px; /* remove or adjust if you want it snug */
}

.privacyIframe {
  width: 800px;
  height: 700px;
}


@media (max-width: 800px) {
  .privacyIframe {
    width: 100%;
    height: 60vh;
  }
}



/* Optional: Make the layout stack on smaller screens */
@media (max-width: 1678px) {
  .formColumns {
    flex-direction: column;
  }
}


.dobContainer {
  display: flex;
  gap: 10px;
}

.signupButton {
  padding: 10px;
  background-color: #55957b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
  width: 100px;
  margin: 20px auto 0;
}

.signupButton:hover {
  background-color: #49846d;
}

/* Error message styling */
.errorMessage {
  color: red;
  font-size: 0.85rem;
  margin: 3px 0;
}

.errorMessageGeneral {
  color: red !important;

  ;
}

/* Paragraph and link style under the form */
.signupBox p {
  margin-top: 15px;
  font-size: 0.9rem;
  color: #333;
}

.signupBox a {
  color: #55957b;
  text-decoration: none;
}

.signupBox a:hover {
  text-decoration: underline;
}

/* Grid container for password requirements */
.passwordRequirementsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 15px;
}

/* Individual requirement item */
.passwordRequirementItem {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #666;
}

/* The round icon for each requirement */
.requirementIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: gray;
  margin-right: 5px;
}

/* When a requirement is met, turn the icon green */
.valid {
  background-color: #48b17d;
}

/* Otherwise, keep it gray */
.invalid {
  background-color: gray;
}

/* Password match indicator styling */
.passwordMatch {
  display: flex;
  align-items: center; 
  font-size: 0.8rem;
  margin-top: 0px;
  color: #666;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything else */
}

.modalContent {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #48b17d; /* Green spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto 15px; /* Centers the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.successIcon {
  font-size: 50px;
  color: #48b17d;
  margin-top: 10px;
  margin-bottom: 5px;
}



/* ----------- MEDIA QUERY for screens <= 1200px ----------- */
@media (max-width: 1200px) {
  .splitContainer {
    flex-direction: column; /* stack left side above right side */
  }

  .leftSide{
    flex: 0;
  }

  .leftSide, 
  .rightSide {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  
  /* If you want the brand container to be vertical when stacked */
  .brandContainer {
    flex-direction: row;
    gap: 10px;
    margin: 20px;
  }

  .brandText {
    text-align: center;
    margin: 0px;
    font-size: 38px;
  }

  .logo {
    width: 80px;
  }
}

@media (max-width: 450px) {
  .brandText {
    font-size: 28px;
  }
  .logo {
    width: 50px;
  }

  .brandContainer {
    height: 30px;
  }

  .signupTitle {
    font-size: 1.2rem;
  }

  label {
    font-size: 0.9rem;
  }

  .formGroup input {
    font-size: 0.9rem;
    height: 40px;
    padding: 0 10px;
  
  };

  .formGroup select {
    font-size: 0.9rem;
    height: 40px;
    align-items: center;
    padding: 0px 10px;

  }
 



}
