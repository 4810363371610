/* src/components/Sidebar.module.css */

.sidebar {
  position: fixed;
  top: 60px; /* below the fixed header */
  left: 0;
  width: 250px;
  height: calc(100% - 60px);
  overflow-y: auto;
  background-color: #fff;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  /* For smooth open/close transitions */
  transform: translateX(0);
  transition: transform 0.3s ease;
  z-index: 999; /* So it appears above the main content on mobile */
}

/* Collapsed always means "narrow" on desktop, so we keep that same style.
   But for mobile, we also want it fully off-canvas. */
.collapsed {
  width: 70px;
  /* For desktop usage (if you still want that narrow look):
     nothing changes. But let's define a default style
     for smaller screens below using media queries. */
}

/* Remove or comment out min-height: 100vh because we now use calc(100% - 60px)
min-height: 100vh;  <-- remove this! */

/* Content Wrapper */
.contentWrapper {
  flex-grow: 1;
}



/* Profile Section */
.profileSection {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
  color: #333;
}


.profileSection:hover {
  background-color: #444;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: opacity 0.3s ease;
}


.username {
  margin-left: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.nav {
  width: 100%;

}

.nav ul {
  list-style-type: none;
  padding: 0;
}

.nav li {
  margin-bottom: 25px;
}

.navLink {
  all: unset;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: padding 0.3s ease; /* Add transition */
  cursor: pointer;
}

.navLink:hover {
  color: #55957b;
}

.navLink:hover .icon {
  color: #55957b;
}

/* Active state: same as hover */
.navLink.active {
  color: #55957b;
  font-weight: bold;
}

.navLink.active .icon {
  color: #55957b;
  
}


.icon {
  margin-right: 15px;
  font-size: 1.2rem;

}

.navLink span {
  flex-grow: 1;
  transition: opacity 0.3s ease; /* Add transition */
}

/* Hover Effects */
.navLink:hover .icon {
  color: #55957b;
}

/* Collapsed Sidebar */
.collapsed {
  width: 70px;
}
/* Collapsed Sidebar Adjustments */
.collapsed .username {
  display: none;
}

.collapsed .avatar {
  margin: 0 auto;
}



.collapsed .username,
.collapsed .navLink span {
  display: none;
}

.collapsed .appName {
  display: none;
}

.collapsed .icon {
  margin-right: 0;
}


.collapsed .navLink span {
  opacity: 0;
  visibility: hidden;
}


.navLink span {
  transition: opacity 0.3s ease; /* Add transition */
}

.docsSentence {
  color: #333;
  font-size: 0.9rem;
}

.docsLink {
  color: #333;
  text-decoration: underline;
}

.docsLink:hover {
  color: #55957b;
  text-decoration: underline;
}

.copyright {
  font-size: 9px;
}
/* Responsive Design */

/* MOBILE OVERRIDES */
@media (max-width: 1000px) {
  .sidebar {
    transform: translateX(-100%); 
    width: 250px;
    top: 50px;
    height: 100%;
    padding-bottom: 80px;
    
  }
  .sidebar:not(.collapsed) {
    transform: translateX(0);
  }
  .collapsed {
    width: 250px; /* so “collapsed = hidden” on mobile */
  }
}
