
.footer {
    color: white;
    padding: 20px;
    text-align: center;
    padding-bottom: 20px;
    background-color: #2c3e50;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }