.dashActivityFeed {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
}

/* One item per row */
.activityItem {
  position: relative; /* so we can absolutely position the timestamp box */
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.activityItem:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.12);
}

/* Map thumbnail container */
.thumbContainer {
  position: relative;
  width: 200px;
  height: 100px;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbContainer svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Fallback if there's no map */
.defaultThumbnail {
  font-size: 0.85rem;
  color: #666;
  text-align: center;
}

/* Bottom-right overlay with avatar + icon */
.activityOverlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.4);
  border-top-left-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.activityAvatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.activityIcon {
  font-size: 1rem;
  color: white;
  margin-top: 2px;
}

/* Right side details */
.activityDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.mainText {
  font-size: 0.95rem;
  color: #333;
  margin: 0 0 6px 0;
  font-weight: 400;
  margin: 10px 0;
}

/* The clickable sender name within mainText */
.senderName {
  font-weight: 600;
  cursor: pointer;
}
.senderName:hover {
  text-decoration: underline;
}

/* The clickable map title link, bold plus pointer */
.mapTitleLink {
  cursor: pointer;
  font-weight: 600;
}
.mapTitleLink:hover {
  text-decoration: underline;
}

/* The new "comment box" with the comment author’s avatar, next to the comment text */
.commentBox {
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 6px 8px;
  gap: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  word-break: break-word;
  align-items: center;
  width: fit-content;
}

.commentAuthorAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer; /* so user can click to go to that person's profile */
  flex-shrink: 0;
  border: 1px solid #ccc;
}

.commentBody {
  font-size: 0.85rem;
  color: #444;
  overflow-wrap: break-word;
}

/* The timestamp box in bottom-right corner */
.timestampBox {
  color: #888;
  font-size: 0.75rem;
  padding: 3px 8px;
  border-radius: 4px;
  width: fit-content;
}

/* The skeleton container that replaces each normal row. */
.skeletonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 12px; /* same spacing as normal items */
  gap: 10px;
}

/* The skeleton for the map thumbnail (gray box). */
.skeletonThumb {
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background: #eee;
  overflow: hidden;
  position: relative;
}

/* The skeleton for the text area */
.skeletonTextBlock {
  flex: 1; /* fill remaining space */
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Each line of text is a gray rectangle */
.skeletonLine {
  height: 14px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

/* The "shimmer" animation – you can tweak speeds, colors, etc. */
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.skeletonShimmer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%);
  animation: shimmer 1.2s infinite;
}

/* Apply the shimmer to each skeleton line & thumb */
.skeletonThumb,
.skeletonLine {
  background-color: #eee;
}
.skeletonThumb::after,
.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.6) 50%, transparent 100%);
  animation: shimmer 1.2s infinite;
}


/* Responsive layout */
@media (max-width: 600px) {
  .activityItem {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px; /* space for the absolute-positioned timestampBox */
  }

  .thumbContainer {
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }

  .timestampBox {
    bottom: 8px;
    left: 8px; /* shift to the left on mobile if you want */
  }

}
