.homeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
}
 
.betaNotice {
  font-size: 2rem;
  color: #55957b;
  margin-bottom: 20px;
}

/* Countdown Timer Styles */
.countdown {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.countItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countNumber {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

.countLabel {
  font-size: 1rem;
  color: #777;
}

/* Preview Section */
.previewSection {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

/* Tilt Image Styles */
.tiltContainer {
  perspective: 1000px;
}

.tiltImage {
  width: 400px;
  transition: transform 0.3s ease;
  transform: rotateY(0deg) rotateX(0deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tiltImage:hover {
  transform: rotateY(10deg) rotateX(10deg);
}

/* Notify Form Styles */
.notifyFormContainer {
  max-width: 400px;
  text-align: left;
}

.notifyForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emailInput {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  padding: 10px;
  font-size: 1rem;
  background-color: #d24b4c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submitButton:hover {
  background-color: #a8463e;
}

.successMessage {
  color: #55957b;
  font-weight: bold;
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
}
