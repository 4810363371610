/* src/components/Dashboard.module.css */

/* GENERAL LAYOUT */
.dashboardContainer {
  display: flex;
  min-height: 100vh;
  background-color: #f7f7f7;
  margin-top:  60px;

}

/* The main content area (beside your global Sidebar component) */
.dashboardContent {
  flex: 1;
  padding: 20px;
  margin-left: 250px; /* Adjust for your global sidebar’s expanded width */
}

.contentCollapsed {
  margin-left: 70px; /* If your sidebar collapses to 70px */
}

/* MAIN WRAPPER: 3 columns side by side. */
.mainWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin: 0 20px;
}

/* LEFT SIDEBAR: recently modified maps */
.leftMapsSidebar {
  width: 350px;           /* Wider than before */
  flex-shrink: 0;
  position: sticky;
  top: 10px;              /* Offset from top, so it's below the Header as user scrolls */
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 20px;
  height: 100%;
  margin-top: 70px;
}

.leftMapsSidebar h2 {
  margin-top: 0;
}

/* List of maps inside the left sidebar */
.recentMapsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mapListItem {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.mapListItem:last-child {
  border-bottom: none;
}

.mapTitle {
  flex-shrink: 0;
  font-weight: 600;
  color: #333;
  margin-right: 10px;
  cursor: pointer;
}
.mapTitle:hover {
  text-decoration: underline;
}

.mapActions {
  display: flex;
  gap: 6px;
  margin-right: 10px;
}


.mapModifiedDate {
  margin-left: auto;
  color: #888;
  font-size: 0.8rem;
  white-space: nowrap;
}

.centerColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


/* The right column that holds Recently Modified + Starred sections */
.sideColumn {
  width: 350px;
  flex-shrink: 0;
  margin-top: 79px;
  background: none;
  padding: 0;

}

/* Each “block” on the side column (Recently Modified, Starred) as a card */
.sectionCard {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;
}

.sectionCard h2 {
  margin-top: 0;
  font-size: 16px;
}

/* List container for multiple "map cards" (each row) */
.mapCardsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Reusable "card" for each map row, similar to your .activityItem */
.mapCard {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.mapCard:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Left side: thumbnail container */
.mapCardThumb {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  margin-right: 10px;
  flex-shrink: 0;
  background-color: #ddd; /* fallback if no real thumbnail */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.defaultThumbnail {
  font-size: 0.8rem;
  color: #666;
}

/* Right side: details (title, times, star count, etc.) */
.mapCardDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mapCardTitle {
  margin: 0 0 4px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
}

.mapCardTimestamp {
  margin: 0 0  0 3px;
  font-size: 0.8rem;
  color: #666;
}

/* For star count inside the card */
.starCount {
  font-size: 0.75rem;
  color: #444;
  margin: 3px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  
}

.editContainer {
  display: flex;
  align-items: center;
}

/* The Edit button inside a mapCard */
.editBtn {
  all: unset;
  color: #333;
  border: none;
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  width: fit-content;
  align-items: center;
}

.editBtn:hover {
  text-decoration: underline;
}

/* The "View All Starred Maps" button */
.viewAllSavedBtn {
  all: unset;
  font-size: 0.75rem;
  margin-top: 10px;
  color: #333;
  border: none;
  padding: 8px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.viewAllSavedBtn:hover {
  text-decoration: underline;
}

/* Example media query to stack columns on narrower screens */
@media (max-width: 1050px) {
  .mainWrapper {
    flex-direction: column;
  }
  .sideColumn {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .mapCardThumb {
    width: 60px;
    height: 40px;
  }
  .mapCardTitle {
    font-size: 0.85rem;
  }
  .mapCardTimestamp {
    font-size: 0.75rem;
  }
}


/* STATS (top of center column) */
.statsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.statItem {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  min-height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.statIcon {
  font-size: 16px;
}
.statLabel {
  font-weight: 500;
  color: #444;
}
.statValue {
  font-weight: bold;
  color: #222;
}




p {
  margin: 10px 0px 10px 0px;
}


/* RIGHT COLUMN: Notifications (not sticky) */
.rightNotifications {
  width: 350px; /* wider than before */
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 20px;
  flex-shrink: 0;
}
.rightNotifications h2 {
  margin-top: 0;
}
.markAllBtn {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}
.markAllBtn:hover {
  background-color: #b1b1b1;
}
.notificationsList {
  margin: 0;
  padding: 0;
  /* no scroll, no max-height to hold only 6 items (we slice in JS) */
}
.notificationItem {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  cursor: pointer;
}
.notificationItem:last-child {
  border-bottom: none;
}
.notificationText {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}
.notificationTime {
  margin: 4px 0 0;
  font-size: 0.8rem;
  color: #999;
}
.unread {
  background-color: #fdfdfd;
}
.read {
  background-color: #fff;
}

/* DELETE MODAL */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modalContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 450px;
  box-sizing: border-box;
  text-align: center;
}
.modalButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.confirmDelete,
.cancelDelete {
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.confirmDelete {
  background-color: #d9534f;
  color: #fff;
}
.confirmDelete:hover {
  background-color: #c9302c;
}
.cancelDelete {
  background-color: #ccc;
  color: #333;
}
.cancelDelete:hover {
  background-color: #bbb;
}


.savedMapsSection {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.savedMapsSection h2 {
  margin-top: 0;
}

.savedMapsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}

.savedMapCard {
  width: 200px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fafafa;
  cursor: pointer;
  transition: box-shadow 0.2s;
  padding: 10px;
}

.savedMapCard:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.savedMapThumbnail {
  width: 100%;
  height: 100px;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.savedMapTitle {
  font-size: 1rem;
  margin: 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.savedMapCreator {
  font-size: 0.85rem;
  color: #666;
  margin: 0;
}



.sidebarOverlay {
  position: fixed;
  top: 60px;          /* same top as the header */
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background: rgba(0,0,0,0.4);
  z-index: 998;       /* just below the sidebar’s 999 */
}

/* Reuse your shimmer animation from the feed, or define it again */
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Generic "placeholder" background + shimmer */
.skeletonRow,
.skeletonStatItem,
.skeletonSectionTitle,
.skeletonMapCard {
  background-color: #eee;
  position: relative;
  overflow: hidden; /* needed for the .::after shimmer */
  border-radius: 4px;
}

/* The shimmer effect across the element */
.skeletonRow::after,
.skeletonStatItem::after,
.skeletonSectionTitle::after,
.skeletonMapCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%);
  animation: shimmer 1.2s infinite;
}

/* For your "stats row" placeholders: same size as .statItem? */
.skeletonStatItem {
  width: 100px;
  height: 40px;
  margin-right: 10px;
}

/* For a skeleton section title */
.skeletonSectionTitle {
  width: 120px;
  height: 18px;
  margin-bottom: 10px;
}

/* For skeleton placeholders in place of map cards or “recently modified” items */
.skeletonMapCard {
  width: 100%;
  height: 60px; /* or bigger if you want a bigger placeholder */
  margin-bottom: 10px;
}

/* 
  For the skeleton Row that mimics an activity item:
  We can copy from your feed’s .skeletonRow, or define 
  differently if you want. 
*/
.skeletonRow {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  padding: 8px;
}

/* 
  A sub-element for the thumbnail portion 
  if you want to replicate the .skeletonThumb usage 
*/
.skeletonThumb {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

/* The text area in an activity item skeleton */
.skeletonTextBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeletonLine {
  width: 100%;
  height: 14px;
  background-color: #eee;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

/* shimmer for the lines too */
.skeletonLine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(to right, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%);
  animation: shimmer 1.2s infinite;
}



/* Tablet-ish - stack columns, reduce widths, but still keep some spacing */
@media (max-width: 1050px) {
  .mainWrapper {
    flex-direction: column;
    /* Possibly reduce side margins, font sizes, etc. */
  }
  .dashboardContent {
    margin-left: 0 !important; 
    width: 100%;
    padding: 5px;
  }
  .contentCollapsed {
    margin-left: 0 !important;
    width: 100%;
  }
  .centerColumn {
    margin: 0 auto;
    width: 100%;
  }


  .leftMapsSidebar {
    padding: 0px
  }
  .leftMapsSidebar h2 {
    padding-top: 15px;
    padding-left: 15px;
  }

  .commentBox {
    margin: 0px;
    padding: 5px;
  }

  h2 {
    font-size: 1rem;
    
  }

  .mapTitle {
    font-size: 14px;
  }

  

  .mapListItem {
    padding: 5px 15px;
  }

  .savedMapsSection {
    margin-top: 0px;
    padding: 10px;
    

  }

  .statsContainer {
    flex-direction: row;
    gap: 5px;  /* smaller gap */
    margin-top: 20px;
  }
  .leftMapsSidebar {
    width: 100%;
    margin-top: 0;
    /* maybe remove that sticky positioning, or keep it */
    position: static;
  }

}

/* Phone-ish - narrower than 600 or 768 for truly small screens */
@media (max-width: 600px) {
  .statsContainer {
    gap: 5px;  /* smaller gap */
    margin-top: 0px;
  }

  .statItem {
    padding: 3px 5px; /* reduce padding for phone */
  }


  
  .thumbContainer {
    flex: 0 0 180px; /* or whatever width you’d like the thumbnail */
    margin-right: 20px;
    margin-bottom: 0;  /* remove extra bottom margin at larger widths */
  }


 
 
  .statItem {
    padding: 0px 10px; /* reduce padding for phone */
    font-size: 12px;
  }

  .statIcon {
    font-size: 14px;
  }

 

  .mapModifiedDate {
    display: none;
  }

  .centerColumn {
    gap:0px;
  }

}

