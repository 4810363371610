.profileContainer {
  display: flex;
  min-height: 100vh;
  color: black;
  margin-top: 60px;
  background-color: #f7f7f7;
  width: 100%;
  overflow-x: hidden;   /* in case any child tries to overflow */

}

.profileContent {
  flex: 1;
  padding: 20px;
  margin-left: 250px; /* Sidebar width when expanded */
  transition: margin-left 0.3s ease;
  font-family: "Roboto", sans-serif;
  color: #333;
  max-width: 100%;


}

/* When sidebar is collapsed */
.profileContent.contentCollapsed {
  margin-left: 70px; /* Adjust based on your collapsed sidebar width */
}

.mainContent {
  width: 100%;

}

.profileForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Individual sections to visually group them */
.settingsSection {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 800px;
  width: 800px;
  margin: 20px auto;
  
  
}

.sectionTitle {
  margin-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  font-size: 1.2rem;
}

/* Form Rows */
.formRow {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.formRow:first-child {
  border-top: 1px solid #ccc;
}

.formLabel {
  width: 30%;
  font-size: 1rem;
  color: #333;
}

.formField {
  width: 70%;
  text-align: right;
}

.formField input,
.formField textarea,
.formField select {
  width: 100%;
  padding: 6px;
  margin-top: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.formField textarea {
  resize: vertical;
}

.saveButton {
  padding: 10px 20px;
  background-color: #55957b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 30px;
  align-self: center;
}

/* Loader styling if needed by LoadingSpinner component */
.loader {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 50px;
}

/* Success and Error Boxes */
.successBox {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 5px;
  border-radius: 10px;
  margin: 20px auto;
  text-align: center;
  font-size: 14px;
  width: fit-content;
}

.errorBox {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 10px;
  margin: 20px auto;
  text-align: center;
  font-size: 14px;
}

/* Buttons */
.changeButton,
.deleteButton {
  padding: 8px 16px;
  background-color: #e0e0e0;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}

.changeButton:hover,
.deleteButton:hover {
  background-color: #d5d5d5;
}

.buttonIcon {
  margin-right: 8px;
}

/* Editable Value */
.editableValue {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.staticValue {
  font-size: 1rem;
  color: #555;
}

.editIcon,
.emptyIconSpace {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.emptyIconSpace {
  visibility: hidden;
}

.editIcon {
  cursor: pointer;
  color: #0078bf;
}

.editIcon:hover {
  color: #005f8a;
}

/* Profile Picture */
.profile_pictureContainer {
  position: relative;
  display: inline-block;
}

.profile_picturePreview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
}

.profile_pictureEditIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: #005f8a;
}

.profile_pictureEditIcon:hover {
  background-color: #f0f0f0;
}

.profile_pictureEditIcon svg {
  width: 16px;
  height: 16px;
}

.profile_pictureInput {
  display: none;
}

/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modalContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
}

.modalContent h2 {
  margin-top: 0;
}

.searchInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.countryList {
  max-height: 300px;
  overflow-y: auto;
}

.countryItem {
  padding: 10px;
  cursor: pointer;
}

.countryItem:hover {
  background-color: #f0f0f0;
}

.modalCloseButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modalCloseButton:hover {
  background-color: #bbb;
}

/* Gender Picker Modal */
.genderList {
  max-height: 200px;
  overflow-y: auto;
}

.genderItem {
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.genderItem:hover,
.selectedItem {
  background-color: #f0f0f0;
}

.selectedItem {
  font-weight: bold;
}

/* Privacy Section */
.privacySection {
  margin-bottom: 30px;
  padding: 10px 0;
}

.privacySelect {
  padding: 4px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  max-width: fit-content;
  


}

/* Toggle Switch */
.toggleButton {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  min-width: 70px;
}

.toggleOn {
  background-color: #55957b;
  color: white;
}

.toggleOff {
  background-color: #e0e0e0;
  color: #666;
}
/* Make the entire row a flex container so heading, warning text,
   and the button can share the same line. */
   .deleteSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 20px;
    background-color: #fff8f8;
    border-radius: 8px;
    border: 1px solid #ffe0e0;
  }
  
  /* You can group heading + warning in a small container */
  .deleteInfo {
    display: flex;
    flex-direction: column;
  }
  
  .deleteHeading {
    margin: 0;
  }
  
  .deleteWarning {
    margin: 5px 0 0 0;
    color: #721c24; /* Red-ish text color if desired */
    font-size: 0.9rem;
  }
  
  .deleteButton {
    background-color: #d24b4c; /* Softer red */
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .deleteButton:hover {
    background-color: #e66a6a; /* Slightly darker on hover */
  }
  

.deleteWarning {
  color: #721c24;
  margin: 10px 0;
  font-size: 0.9rem;
}

/* DELETE MODAL */
.modalButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.confirmDelete,
.cancelDelete {
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.confirmDelete {
  background-color: #d9534f;
  color: #fff;
}
.confirmDelete:hover {
  background-color: #c9302c;
}
.cancelDelete {
  background-color: #ccc;
  color: #333;
}
.cancelDelete:hover {
  background-color: #bbb;
}

/* ...existing styles from your snippet above... */

/* ------------------------------
   Skeleton Styles
------------------------------ */

/* Container for the entire skeleton */
.skeletonContainer {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
}

/* Each "section" box in the skeleton */
.skeletonSection {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* A skeleton row with label + field placeholders */
.skeletonRow {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* Label placeholder */
.skeletonLabel {
  width: 30%;
  height: 16px;
  border-radius: 4px;
  background-color: #eee;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
.skeletonField {
  width: 70%;
  height: 16px;
  border-radius: 4px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

/* We want a bigger circle for the profile pic row */
.skeletonPic {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.skeletonPic::before {
  content: "";
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

/* For the section title line at top */
.skeletonSectionTitle {
  width: 180px;
  height: 18px;
  margin-bottom: 15px;
}

/* The Save button skeleton at bottom */
.skeletonSaveButton {
  width: 160px;
  height: 35px;
  border-radius: 4px;
  background-color: #eee;
  margin: 20px auto 0 auto;
}

/* Shimmer animation for all placeholders */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Make all skeleton blocks shimmer */
.skeletonContainer .skeletonLabel::after,
.skeletonContainer .skeletonField::after,
.skeletonContainer .skeletonPic::before,
.skeletonContainer .skeletonSectionTitle::after,
.skeletonContainer .skeletonSaveButton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}

/* For the circle, we absolutely position relative to .skeletonPic::before */
.skeletonContainer .skeletonPic::before {
  content: "";
  animation: shimmer 1.2s infinite;
}

.skeletonContainer .skeletonSectionTitle {
  position: relative;
}
.skeletonContainer .skeletonLabel,
.skeletonContainer .skeletonField,
.skeletonContainer .skeletonSaveButton {
  position: relative;
}
.skeletonContainer .skeletonPic::before {
  position: relative; /* to hold the circle shape */
  overflow: hidden;
  display: inline-block;
}



/* Responsive Adjustments */
@media (max-width: 1000px) {
  .profileContent {
    margin-left: 0 !important; 
    width: 100%;
  }
  .profileContent.contentCollapsed {
    margin-left: 0 !important;
  }
}

/* -----------------------------------
   Mobile-Friendly Adjustments
   ----------------------------------- */

/* On screens under 800px wide, let each .settingsSection take full width */
@media (max-width: 800px) {
  .settingsSection {
    width: 100%;
    max-width: 100%;
    margin: 10px auto; /* smaller outer margins */
    padding: 15px;     /* a bit less padding */
  }
}

/* On screens under 600px wide, stack label + field in each row */
@media (max-width: 600px) {
  .formRow {
    flex-direction: column;
    align-items: flex-start; /* so label and input both left-aligned */
    padding: 10px 0;         /* tighten vertical spacing */
  }

  .formLabel {
    width: 100%;
    margin-bottom: 6px;
    text-align: left;  /* place the label text on the left side */
    font-size: 0.9rem; /* reduce font size a bit if needed */
  }

  .formField {
    width: 100%;
    text-align: left;  /* align input text to the left as well */
  }

  /* Tweak input & button sizes for smaller screens */
  .formField input,
  .formField textarea,
  .formField select {
    font-size: 0.9rem;
    padding: 8px;
  }

  .saveButton {
    font-size: 0.9rem;
    padding: 10px 18px;
    margin-top: 20px;
  }

  /* Profile picture can be smaller */
  .profile_picturePreview {
    width: 80px;
    height: 80px;
  }

  .settingsSection {
    max-width: 90%;
  }

  .profileContent {
    padding-top: 0px;
  }

  .deleteSection {
    padding: 10px;
    font-size: 12px;
  }

  .deleteWarning {
    font-size: 9px;
  }

  .deleteButton {
    padding: 8px 16px;
    font-size: 12px;
    width: fit-content;
    justify-content: center;
  }

  input {
    max-width: 95%;
  };

  textarea {
    max-width: 95%;
  };







 
}
