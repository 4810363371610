/* src/components/LoadingSpinner.module.css */

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px; /* Adjust as needed */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #ffffff; /* Light blue */
    border-top-color: #55957b; /* Primary blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Spin Animation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  