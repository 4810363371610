/* The entire demo section filling the screen */
.demoSection {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  /* We'll stack the title block on top, and the row beneath */
  display: flex;
  flex-direction: column;
  justify-content: center;  /* vertical centering if there's extra space */
  padding: 20px;
  gap: 20px; /* space between titleBlock and contentRow */
}

/* Title block at the top, centered text */
.titleBlock {
  
  text-align: center;
  margin-top: 20px; /* no huge gap below the subtitle */
  margin-bottom: 0px;
}

.title {
  font-size: 2rem;
  margin: 0;
  color: #333;
}

.subtitle {
  margin-top: 8px; /* small space under the title */
  color: #666;
  font-size: 1rem;
}

/* The row with map + info side by side on large screens */
.contentRow {
  display: flex;
  flex: 1; /* take remaining vertical space */
  gap: 40px;
  align-items: center; /* vertical align center of row */
  justify-content: center; /* horizontal center if there's leftover space */
}

/* Map container with rounded corners & shadow. 
   Hover effect for a slightly larger shadow. */
.mapContainer {
  position: relative; /* for badge overlay */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 1rem;     /* "rounded-xl" style */
  transition: box-shadow 0.2s ease;
  padding: 10px;
}



/* Overlaid "Built with MIC" badge */
.micBadge {
  position: absolute;
  bottom: 8px;
  right: 12px;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Info container with max-width ~ 400-500px for readable text */
.infoContainer {
  flex: 1;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

/* Map Title in the info box */
.mapTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.mapMeta {
  font-size: 0.9rem;
  color: #999;
  margin-bottom: 20px;
}

.mapDescription {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

.mapReferences {
  font-size: 0.9rem;
  color: #777;
}

.mapReferences a {
  color: #d24b4c;
  text-decoration: underline;
}

/* Mobile/Tablet: stacked layout (map on top, text below) */
@media (max-width: 1300px) {
  .demoSection {
    height: auto; /* let it expand if content is too tall */
  }

  .contentRow {
    flex-direction: column;
    gap: 20px;
  }

  .mapContainer,
  .infoContainer {
    width: 100%;
  }

  .mapTitle {
    font-size: 1.25rem;
  }

  .micBadge {
    bottom: 4px;
    right: 8px;
  }
}
