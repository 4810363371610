/* BannedUser.module.css */
.bannedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    text-align: center;
  }
  
  .bannedContainer h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .bannedContainer p {
    margin-bottom: 2rem;
  }
  