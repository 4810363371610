/* MapDetail.module.css */

/* Container around the sidebar & main content */
.mapDetailContainer {
  display: flex;
  background-color: #f7f7f7;
  min-height: 1000px;
  margin: 0 auto;

}

/* The main content area next to the sidebar */
.mapDetailContent {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


/* ------------------------- */
/* MAP DISPLAY AREA          */
/* ------------------------- */

.mapDisplay {
  position: relative;
  overflow: hidden;
  background-color: #f7f7f7;
  width: 100%;
  margin: 0 auto;
}

/* Zoom Controls (top-right) */
.zoomControls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.zoomControls button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 5px;
}
.zoomControls button:hover {
  background-color: #f0f0f0;
}

/* Full Screen Button (bottom-right) */
.viewModeButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
}
.viewModeButton:hover {
  background-color: #f0f0f0;
}

/* ------------------------- */
/* DETAILS & STATS LAYOUT    */
/* ------------------------- */

/* We have 2 columns:
   1) leftContent (mapDetails + discussion)
   2) mapStats
*/
.detailsAndStats {
  display: flex; /* 2 columns on desktop */
  width: 100%;
  margin-top: 20px;
  gap: 20px; /* If you want a gap */
}

/* Left column */
.leftContent {
  flex: 3;
  display: flex;
  flex-direction: column;
  /* put discussion below mapDetails inside here */
}

/* The box with map details (title, tags, references, etc.) */
.mapDetails {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

/* The discussion section (below mapDetails on desktop) */
.discussionSection {
  margin-top: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

/* Right column: Stats + country table */
.mapStats {
  flex: 1;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

/* Zoomed to smaller widths if needed */
.statsSummary {
  flex-shrink: 0;
}
.statItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.statValue {
  font-size: 32px;
  font-weight: bold;
  margin-right: 10px;
}
.statLabel {
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}
.statItem p {
  margin-left: auto;
  font-size: 16px;
  color: #555;
}

/* Country List Table */
.countryList {
  overflow-y: auto;
  margin-top: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.countryList table {
  width: 100%;
  border-collapse: collapse;
}
.countryList th,
.countryList td {
  text-align: left;
  padding: 8px;
}
.countryList tr:nth-child(even) {
  background-color: #f2f2f2;
}
.countryList th {
  background-color: #e0e0e0;
}

/* ------------------------- */
/* TITLE & CREATOR           */
/* ------------------------- */
.titleSection {
  display: flex;
  align-items: center;
}

.titleSection h1 {
  flex: 1;
  margin: 0;
}
.saveButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  gap: 3px;
}
.created_at {
  margin-top: 5px;
  margin-bottom: 10px;
}
.creatorInfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.creatorProfileLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-right: 10px;
}
.creatorProfilePicture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.creatorName {
  font-size: 16px;
  font-weight: bold;
}
.visibilityTag {
  display: inline-block;
  margin-left: 10px;
  padding: 2px 6px;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  border-radius: 4px;
}
.description {
  margin-top: 10px;
  font-size: 16px;
}

/* Tags */
.mapTag {
  display: inline-block;
  color: #333;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  border: 0.5px solid #ccc;
  border-radius: 7px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.15s ease, color 0.15s ease;
}
.mapTag:hover {
  background-color: #79a6b7;
  color: #fff;
}

/* References list */
.referencesList {
  list-style: decimal inside;
  margin-left: 0;
  padding-left: 0;
  margin-top: 8px;
}
.referenceItem {
  margin-bottom: 10px;
  line-height: 1.5;
}
.referenceNotes {
  margin-top: 5px;
  margin-left: 20px; /* indent "Notes:" if you like */
  font-style: italic;
}

/* ------------------------- */
/* DISCUSSION / COMMENTS     */
/* ------------------------- */
.discussionSection h2 {
  margin-top: 0;
}
.commentForm {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.commentTextarea {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
  min-height: 40px;
}
.commentTextarea:focus {
  outline: none;
  border-color: #79a6b7;
}
.commentButton {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #55957b;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
  align-self: center;
}
.commentButton:hover {
  background-color: #6999b1;
}
.commentButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  filter: none;
}

/* Comments List */
.commentsList {
  list-style-type: none;
  padding: 0;
}
.commentItem {
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
}
.commentHeader {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.commentProfilePicture,
.commentPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.commentPlaceholder {
  background-color: #ccc;
}
.commentContentWrapper {
  flex: 1;
}
.commentInfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.commentAuthor {
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: black;
}

a {
  text-decoration: none;
}
.commentTime {
  font-size: 12px;
  color: #888;
}
.commentContent {
  font-size: 18px;
  margin: 0;
}

/* Comment Actions (Like/Dislike/Reply/Delete) */
.commentActions {
  display: flex;
  flex-wrap: wrap; /* allow wrapping onto a new line */
  align-items: center;
  margin-top: 10px;
  gap: 8px; /* optional: adds spacing between buttons */
}
.reactionButton {
  display: inline-flex;
  align-items: center;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  color: #555;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  padding: 4px 8px;
  transition: background-color 0.2s, color 0.2s;
}
.reactionButton:hover {
  background-color: #e0e0e0;
}
.reactionButton.active {
  background-color: #79a6b7;
  color: #fff;
}
.reactionButtonSmall {
  padding: 4px 8px;
  font-size: 0.8rem;
}
.iconSmall {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.deleteButtonSmall {
  font-size: 0.8rem;
  padding: 4px 8px;
}


.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: currentColor;
}
.replyButton {
  background: none;
  border: none;
  color: #79a6b7;
  cursor: pointer;
  margin-left: 10px;
}
.replyButton:hover {
  text-decoration: underline;
}

/* Reply Form + Replies */
.replyForm {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 65px;
}
.replyTextarea {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  resize: none;
  min-height: 60px;
}
.replyTextarea:focus {
  outline: none;
  border-color: #79a6b7;
}
.replyButtonSubmit {
  align-self: flex-end;
  margin-top: 5px;
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  background-color: #79a6b7;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.replyButtonSubmit:hover {
  background-color: #6999b1;
}
.repliesList {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  margin-left: 65px;
}
/* ----------------------------------- */
/* Make replies align further left and show L-shaped lines */
/* ----------------------------------- */

/* Remove or reduce the big margin-left if you like. */
.replyItem {
  position: relative;
  margin-left: 10px; /* you can adjust this as needed */
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  display: flex;
  border-radius: 8px;
}

/* The "L-shaped" lines go here */
.replyItem::before,
.replyItem::after {
  content: "";
  position: absolute;
  background-color: #ccc; /* line color */
}

/* Vertical line */
.replyItem::before {
  left: -20px;   /* positions the vertical line to the left of the box */
  top: 0;        /* from the top of the replyItem */
  width: 2px;    /* thickness of the vertical line */
  height: 100%;  /* full height of the reply */
}

/* Horizontal line (the top bar of the "L") */
.replyItem::after {
  left: -20px;
  top: 25px;     /* how far down from the top the horizontal bar appears */
  width: 20px;   /* length of the horizontal bar */
  height: 2px;   /* thickness of the horizontal bar */
}

.replyItem .commentHeader {
  display: flex;
  width: 100%;
}
.replyItem .commentProfilePicture,
.replyItem .commentPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.replyItem .commentContentWrapper {
  flex: 1;
  font-size: 14px;
}
.replyItem .commentAuthor {
  font-weight: bold;
  font-size: 13px;
  margin-right: 8px;
}
.replyItem .commentTime {
  font-size: 11px;
  color: #888;
}
.replyItem .commentContent {
  font-size: 14px;
  margin: 0;
}
.replyItem .commentActions {
  margin-top: 8px;
}
.replyItem .reactionButton {
  font-size: 12px;
  padding: 3px 6px;
  margin-right: 8px;
}
.replyItem .icon {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}
.replyItem + .replyItem {
  margin-top: 15px;
}
.replyCancelButton {
  margin-left: 8px;
  margin-top: 8px;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
}
.replyCancelButton:hover {
  text-decoration: underline;
}
.replyActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
}
.toggleRepliesButton {
  background: none;
  border: none;
  color: #79a6b7;
  cursor: pointer;
  margin-left: 65px;
  margin-bottom: 10px;
  font-size: 13px;
}
.toggleRepliesButton:hover {
  text-decoration: underline;
}

/* Edit/Delete Buttons */
.editButton {
  background: #79a6b7;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.editButton:hover {
  background: #5390ae;
}
.deleteButton {
  color: #bb655b;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  background: none;
}
.deleteButton:hover {
  text-decoration: underline;
}
/* Modal Overlay: covers full screen with a semi-transparent backdrop */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content Container */
.modalContent {
  position: relative; /* So the close button can be positioned absolutely */
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 900px; /* Wider max-width for a more spacious layout */
  width: 100%;
  overflow: hidden; /* Ensures no content bleeds outside corners */
}


/* Close (X) Button */
.modalCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 1;
}

/* Left Side */
.modalLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
}

/* Title */
.modalTitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.2;
}

/* Subtitle */
.modalSubtitle {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}

/* Main CTA Button */
.signupButton {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* Example color palette – tweak to match your brand */
  background-color: #d24b4c;
  color: #fff;
  margin-bottom: 1rem;
}

/* Login prompt text */
.loginPrompt {
  font-size: 0.95rem;
}

.loginLink {
  color: #79a6b7;
  text-decoration: underline;
  cursor: pointer;
}

/* Right Side: Image Container */
.modalRight {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9; /* Subtle background for contrast */
  padding: 1rem;
  box-sizing: border-box;
}

/* Image */
.modalImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


.modalProfilePicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

/* Report Options as a vertical list */
.reportOptions {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.reportOption {
  margin-bottom: 0.5rem;
}

/* Textarea styling when "Other" is selected */
.reportDetails textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.5rem;
}

/* Modal Actions: Submit and Cancel buttons */
.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.modalActions button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modalActions button:first-child {
  background-color: #79a6b7;
  color: #fff;
}

.modalActions button:last-child {
  background-color: #ccc;
  color: #333;
}

.referenceItem a {
  display: inline-block;       /* so we can constrain/wrap it */
  max-width: 100%;             /* so it never exceeds parent width */
  word-wrap: break-word;       /* older name for line-breaking long words */
  overflow-wrap: break-word;   /* modern name for line-breaking */
  white-space: normal;         /* ensure the text can wrap */
}



@media (max-width: 600px) {
  .modalContent {
    flex-direction: column;
    width: 100%;       /* Let it be full width */
    margin: 0px;    /* Center it */
    padding: 1rem;     /* Slightly reduce padding to fit on phone screens */
    
  }

  /* (If you want smaller text) */
  .modalTitle {
    font-size: 1.4rem;
  }
  .modalSubtitle {
    font-size: 1rem;
  }

  .modalContent {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
  }

  .mapDetailContent {
    padding: 0px;
  }
  
}
@media (max-width: 600px) {
  .modalRight {
    margin-top: 1rem; /* so it doesn’t clash with the text above */
  }
  .modalImage {
    max-width: 100%;
    height: auto;
  }


}


.loadingContainer,
.successContainer {
  text-align: center;
  padding: 2rem;
}

/* In MapDetail.module.css */
.privateMapBox {
  margin: 60px auto;
  text-align: center;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.privateMapBox h2 {
  font-size: 1.4rem;
  margin-bottom: 8px;
}
.privateMapBox p {
  font-size: 1rem;
  color: #666;
}
.lockIcon {
  font-size: 3rem;
  color: #444;
  margin-bottom: 16px;
}





/* ----------------------------------- */
/* Responsive Breakpoints              */
/* ----------------------------------- */

/* 1) Remove left margin below 1000px */
@media (max-width: 1000px) {
  .mapDetailContent {
    margin-left: 0 !important;
    width: 100%;
  }
  .contentCollapsed {
    margin-left: 0 !important;
  }
}

/* 2) Tablet-ish layout changes ~768px: 
   keep left (mapDetails+discussion) and right (stats) side by side, 
   but use smaller font for stats.
*/
@media (max-width: 1400px) {
  .detailsAndStats {
    display: flex; 
  }
  .mapStats {
    font-size: 0.9rem;
  }
  .statValue {
    font-size: 1.2rem;
  }
  .statLabel {
    font-size: 0.8rem;
    padding: 3px 6px;
  }
  .commentItem {
    padding: 10px;
  }
  .titleSection h1 {
    font-size: 1.2rem;
  }
  .description {
    font-size: 0.9rem;
  }
  .commentContent {
    font-size: 16px;
  }

  .commentButton {
    font-size: 12px;
    padding: 8px 12px;
  }

}

/* 3) Phones (<600px): reorder so: 
    1) mapDetails, 
    2) stats, 
    3) discussion 
*/
@media (max-width: 600px) {
  .detailsAndStats {
    flex-direction: column;
  }
  .leftContent {
    order: 1;
  }
  .mapStats {
    order: 2;
    margin-top: 20px;
  }
  .discussionSection {
    order: 3; /* now below stats */
  }

  /* Stats in a row if you prefer */
  .statsSummary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .statItem {
    flex: 1 1;
    min-width: 100px;
    text-align: center;
    flex-direction: column;
  }

  .statItem p {
    margin: 5px 0;
  }
  .statValue {
    font-size: 1rem;
  }
  .statLabel {
    font-size: 0.7rem;
    margin-top: 5px;
  }
  .repliesList {
    margin-left: 0px;
  }
  .reactionButton  {
    font-size: 12px;
  }

  .commentProfilePicture {
    width: 40px;
    height: 40px;
  }
  .commentActions {
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-start;
  }

  .reactionButton {
    margin-right: 4px; 
    margin-bottom: 6px;  /* so rows aren't too cramped */
    font-size: 13px;     /* optionally decrease font-size */
  }
 
  .titleSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .editButton {
    font-size: 0.8rem;
    padding: 4px 6px;
    margin: 10px 0;
   
  }

  .discussionSection {
    padding: 10px;
  }

  .mapDetails {
    padding: 10px;
  }

  .saveButton {
    margin-left: 0;
  }


}

/* 4) Very small phones (~400px) for further tweaks */
@media (max-width: 400px) {
  
 

  .mapStats {
    padding: 10px;
  }

 
}
