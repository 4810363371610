.splitContainer {
  display: flex;
  height: 100vh; /* Full viewport height */
  font-family: Arial, sans-serif;
  position: relative; /* so the button can be absolutely positioned */
}

/* The go-back button in the top-left corner */
.goBackButton {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  
}




.leftSide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom right, #79a6b7, #79a6b7);
}

/* Container to hold the logo and brand text side by side */
.brandContainer {
  display: flex;
  align-items: center;
  gap: 20px; /* space between logo and text */
}

.logo {
  width: 130px;
  height: auto;
}

.brandText {
  color: #fff;
  font-size: 4rem;
  font-weight: bold;
}

/* Right side: for the form */
.rightSide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

/* Container for the login heading and form */
.loginBox {
  max-width: 400px;
  width: 80%;
  text-align: center;
}

.loginTitle {
  margin-bottom: 30px;
  font-size: 28px;
  color: #333;
}

/* The login form */
.loginForm {
  display: flex;
  flex-direction: column;
}

/* Individual form groups (label + input) */
.formGroup {
  margin-bottom: 20px;
  text-align: left;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.formGroup input {
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5; /* Adjust line-height to prevent text overlap */
}

/* Button styling */
.loginButton {
  padding: 14px;
  background-color: #79a6b7;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 17px;
  margin-top: 10px;
}

.loginButton:hover {
  background-color: #6d8e9f;
}

/* Paragraph and link style under the form */
.loginBox p {
  margin-top: 30px;
  font-size: 15px;
  color: #333;
}

.loginBox a {
  color: #79a6b7;
  text-decoration: none;
}

.loginBox a:hover {
  text-decoration: underline;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything else */
}

.modalContent {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #79a6b7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto 15px; /* Centers the spinner horizontally */
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* ----------- MEDIA QUERY for screens <= 1200px ----------- */
@media (max-width: 1200px) {
  .splitContainer {
    flex-direction: column; /* stack leftSide above rightSide */
  }

  .leftSide{
    flex: 0;
  }

  .leftSide,
  .rightSide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  /* In case you want the branding to appear as a column when stacked */
  .brandContainer {
    flex-direction: row;
    gap: 10px;
    margin: 20px;
  }
  
  .brandText {
    text-align: center;
    margin: 0px;
    font-size: 38px;
  }

  .logo {
    width: 80px;
  }
}

@media (max-width: 450px) {
  .brandText {
    font-size: 28px;
  }
  .logo {
    width: 50px;
  }

  .brandContainer {
    height: 30px;
  }

  .loginTitle {
    font-size: 1.2rem;
  }

  label {
    font-size: 0.9rem;
  }

  .formGroup input {
    font-size: 0.9rem;
    height: 35px;
    padding: 0 10px;
  
  };

  .formGroup select {
    font-size: 0.9rem;
    height: 35px;
    align-items: center;
    padding: 0px 10px;

  }
 



}
