.footer {
  background-color: #79a6b7;
  /* We want two distinct rows stacked. */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
  color: #ffffff;
}

/* First row: social links */
.topRow {
  display: flex;
  justify-content: center;  /* center horizontally */
  align-items: center;
  gap: 20px;                /* spacing between icons */
}

/* Change icon hover effects if you want */
.topRow a {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.topRow a:hover {
  opacity: 0.8;
}

/* Second row: one row containing contact, terms, privacy, copyright */
.bottomRow {
  display: flex;
  justify-content: center; /* or space-between, or whatever you prefer */
  align-items: center;
  gap: 30px;               /* spacing between these groups */
  flex-wrap: wrap;         /* allow wrapping if smaller screens */
}

/* Contact info (icon + email) */
.contactInfo {
  display: flex;
  align-items: center;
  gap: 8px;  /* space between icon and email text */
}

.contactInfo a {
  color: #ffffff;
  text-decoration: underline;
}

/* Terms & Privacy links container */
.linkGroup {
  display: flex;
  gap: 15px; /* space between the two links */
}

.linkGroup a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: opacity 0.2s ease;
}

.linkGroup a:hover {
  opacity: 0.8;
}

/* If you want to style the copyright text, do so here */
.bottomRow p {
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 700px) {
  .bottomRow {
    gap:10px
  }
}